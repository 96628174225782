import { useContext, useState } from "react"
import { OverlayContext } from "../components/withOverlay/WithOverlay"

type OverlayActions = {
  show: () => void,
  hide: () => void
}

/**
 * Hook for accessing the overlay
 * @returns The methods for manipulating the overlay
 */
export const useOverlay = (): OverlayActions => {
  const setShowOverlay = useContext(OverlayContext);
  const overlayActions: OverlayActions = {
    show: () => setShowOverlay(true),
    hide: () => setShowOverlay(false)
  };
  const [overlay] = useState<OverlayActions>(overlayActions);
  return overlay;
}


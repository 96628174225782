import React, { useEffect, useState } from 'react';
import bemify from '../../general/bemUtils';

const defaultDebounceMs = 500;

type DebouncedTextboxProps = {
  text: string,
  onTextChange: (text: string) => void,
  debounceMs?: number,
  legend?: string
}

/**
 * This component renders a textbox whose onTextChange event will not fire until a specified number of milliseconds
 * has elapsed from the last time data in the textbox was changed by the user.
 */
const DebouncedTextbox = ({text, onTextChange, debounceMs: rawDebounceMs, legend}: DebouncedTextboxProps) => {
  //Use the default debounce interval if not specified as a prop
  const debounceMs = rawDebounceMs === undefined ? defaultDebounceMs : rawDebounceMs;

  //Store the actual live text of the textbox in the local state
  const [enteredText, setEnteredText] = useState(text);

  //If the text prop is changed by the parent, propagate this to the local state value of the text in the textbox
  useEffect(() => {
    setEnteredText(text);
  }, [text]);

  //When the user changes text in the box, wait the specified number of milliseconds before firing the change event
  useEffect(() => {
    const timeout = window.setTimeout(() => {
      onTextChange(enteredText);
    }, debounceMs);
    return () => window.clearTimeout(timeout);
  }, [enteredText, debounceMs, onTextChange]);

  const [block, element] = bemify('debouncedTextbox');
  return <span className={block()}>
    {legend &&
      <span className={element('legend')}>{legend}</span>
    }
    <input type="text" className={element('input')} value={enteredText} onChange={evt => setEnteredText(evt.target.value)} onFocus={evt => evt.target.select()} />
  </span>
}

export default DebouncedTextbox;

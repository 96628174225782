import React from 'react';
import PropTypes from 'prop-types';
import bemify from '../../utils/bemUtils';
import Overlay  from '../overlay/overlay';

/**
 * Represents a modal component.
 * @param {Object} props The component properties.
 */
const Modal = (props) => {
  const {
    children,
    show
  } = props;
  const [block, _] = bemify('modal');

  return (
    <Overlay show={show}>
      <div className={block(show ? 'visible' : '')}>
        {children}
      </div>
    </Overlay>
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired
};

Modal.defaultProps = {
  show: false
};

export default Modal;

import React, { Component } from 'react';

import ImportExportIcon from '@material-ui/icons/ImportExport';
// @material-ui/core;
import Overlay from '../../framework/overlay/overlay';
import lodash from 'lodash'; //for sorting/filtering
import Utils from '../../general/utils';
import Checkbox from '../controls/checkbox/checkbox';
import Radio from '../controls/radio/radio';
import bemify from '../../general/bemUtils';
import { Pagination } from '../pagination/pagination';

declare const parseInt: (string: string | number, radix?: number) => number;

const defaultPageSize = 10;

export type ColumnHeader<T> = {
  field: string,
  headerName: string,
  toStringFunc?: (value: any) => string,

  //overrides normal behavior, but if it returns undefined it will "over-override" this and return to
  //normal behavior for this cell
  customContent?: (row: T, rowIndex: number, column: ColumnHeader<T>) => JSX.Element | undefined
}
type MainDataGridProps<T> = {

  //The data to go into the grid
  data: T[],

  //The column configuration
  headers: ColumnHeader<T>[],

  //Whether to show the header row, defaults to true
  showHeaders?: boolean,

  //If supplied, will display this message in a spanned row when there are no records
  emptyTableMessage?: string,

  //Page size, defaults to 10
  pageSize?: number,

  //Paging will occur only with records starting from this index and beyond, while anything before this will always remain
  //on the top of the grid. Rows before this index will also always be included in all searches and filters. Defaults to 0.
  pagingStartIndex?: number,

  //Enable client side filtering
  enableFiltering?: boolean,

  //Enable client side sorting
  enableSorting?: boolean,

  //Enable client side searching
  enableSearching?: boolean,

  //Enable client side paging
  enablePaging?: boolean,

  //The selected rows. If supplied, a row selection column will be added on the left side of the table. If omitted,
  //row selection will be disabled
  selectedRows?: T[],

  //Row selection callback
  onSelectedRowsChange?: (selectedRows: T[]) => void,

  //Determines whether row selection will render checkboxes (multiple) or radio buttons (single). Defaults to multiple.
  selectionMode?: "single" | "multiple",

  //Determines whether the row selection column will be placed before or after the other columns. Defaults to first.
  selectionColumnPosition?: "first" | "last",

  //The text to display in the column header for the row selection column. If omitted in multiple-select mode, a "select all"
  //checkbox will be placed there.
  selectionColumnHeader?: string,

  //Comparison operator to determine that two rows should be treated as identical, used in row selection logic. If
  //omitted, row equality is determined by the "===" operator. This should be omitted unless the consumer is supplying
  //different distinct objects in the selectedRows[] and data[] arrays, which would break row selection logic.
  rowsEqual?: (row1: T, row2: T) => boolean,

  //Parameter to pass into bemify, "maindatagrid" by default
  bemifyParam?: string
}
type MainDataGridState<T> = {
  currentPage: number,
  isLoading: boolean,
  pageSize: number,
  totalPages: number,
  customGridColumns: ColumnHeader<T>[],
  customGridRowData: T[],
  visibleRowData: T[],
  filter: string,
  filterField: string,
  filterOperation: string,
  filterValue: string,
  sortAsc: boolean,
  lastSortedField: string,
  searchValue: string,
  radioName: string,
  block: (modifiers?: string) => string,
  element: (element: string, modifiers?: string | string[]) => string
}

/**
 * Renders data as an HTML table, and optionally certain client side features like sorting, filtering, and paging
 */
class DataGrid<T> extends Component<MainDataGridProps<T>, MainDataGridState<T>> {
  private static instanceCounter: number = 1;

  constructor(props: MainDataGridProps<T>) {
    super(props);

    const pageSize = props.enablePaging ? (props.pageSize ?? defaultPageSize) : Number.MAX_SAFE_INTEGER;

    let numPages = parseInt(props.data.length / pageSize, 10);
    numPages += props.data.length % pageSize != 0 ? 1 : 0;
    if (numPages <= 0 || numPages === null) {
      numPages = 1;
    }

    //Ensure all radio buttons on this grid have the same name that does not clash with other grids
    const radioName = `datagrid${DataGrid.instanceCounter}`;
    DataGrid.instanceCounter++;

    const [block, element] = bemify(props.bemifyParam ?? "maindatagrid");

    this.state = {
      currentPage: 1,
      isLoading: true,
      pageSize: pageSize,
      totalPages: numPages,
      customGridColumns: props.headers,
      customGridRowData: props.data,
      visibleRowData: [],
      filter: '',
      filterField: '',
      filterOperation: '',
      filterValue: '',
      sortAsc: false,
      lastSortedField: '',
      searchValue: '',
      radioName: radioName,
      block: block,
      element: element
    };

  }

  componentDidMount() {
    //make init api call here if going to modify to allow component to manage data retrieval
    // if going to use infinite scroll instead of pagination, wire up event to handle scroll->call api

    this.setState({
      isLoading: false
    });
  }

  componentDidUpdate(prevProps: MainDataGridProps<T>) {
    if(prevProps !== this.props &&
       (!Utils.arrayCompare(prevProps.data, this.props.data) ||
        !Utils.arrayCompare(prevProps.headers, this.props.headers, (header1, header2) => header1.field === header2.field && header1.headerName === header2.headerName) ||
        prevProps.pageSize !== this.props.pageSize)) {
      const pageSize = this.props.enablePaging ? (this.props.pageSize ?? defaultPageSize) : Number.MAX_SAFE_INTEGER;
      let numPages = parseInt(this.props.data.length / pageSize, 10);
      numPages += this.props.data.length % pageSize != 0 ? 1 : 0;
      if (numPages <= 0 || numPages === null) {
        numPages = 1;
      }
      this.setState({
        currentPage: 1,
        pageSize: pageSize,
        totalPages: numPages,
        customGridColumns: this.props.headers,
        customGridRowData: this.props.data
      });
  
    }
  }

  setPageSize(newPageSize: number) {
    this.setState({pageSize: newPageSize});
  }

  rowSelectionEnabled = (): boolean => {
    return this.props.selectedRows !== undefined;
  }

  rowsEqual = (row1: T, row2: T): boolean => {
    return (this.props.rowsEqual !== undefined) ? this.props.rowsEqual(row1, row2) : (row1 === row2);
  }

  //send in pageRows if already known to avoid recalculating. If sending in, it should already exclude non page rows
  allRowsSelected = (pageRows?: T[]): boolean => {
    const pageRowsToUse: T[] = pageRows === undefined ? this.excludeNonPageRows() : pageRows;
    return pageRowsToUse.length > 0 && pageRowsToUse.every(item => this.isRowSelected(item));

    //Use the below two lines instead of above lines if users should be able to select rows across pages
    //Ctrl+F SelectAcrossPages for other places in this file that would need to be changed

    //const pagingStartIndex = this.getPagingStartIndex();
    //return this.props.data.filter((_, index) => index >= pagingStartIndex).every(item => this.isRowSelected(item));
  }

  isRowSelected = (row: T): boolean => {
    let selected = false;
    if(this.props.selectedRows !== undefined) {
      selected = this.props.selectedRows.some(selectedRow => this.rowsEqual(selectedRow, row));
    }
    return selected;
  }

  isMultiSelect = (): boolean => {
    return (this.props.selectionMode !== "single"); //is either "multiple" or undefined
  }

  getPagingStartIndex = (): number => {
    const pagingStartIndex = this.props.pagingStartIndex === undefined ? 0 : this.props.pagingStartIndex;
    return pagingStartIndex;
  }

  //Gets all the rows in the current page that are not excluded from the paging system via the pagingStartIndex prop.
  //For convenience, can take either an array of page rows or an object whose signature matches the output of getPageRows. The
  //parameter is optional, if omitted getPageRows() will be called to supply the page rows.
  excludeNonPageRows = (pageRows?: T[] | { pageRows: T[], itemCount: number }): T[] => {
    const pagingStartIndex = this.getPagingStartIndex();
    const pageRowsArr: T[] = pageRows === undefined ? this.getPageRows().pageRows : (Array.isArray(pageRows) ? pageRows : pageRows.pageRows);
    return pageRowsArr.filter((_, index) => index >= pagingStartIndex);
  }

  handleSelectAllRowsClick = (): void => {
    if(this.isMultiSelect() && this.props.onSelectedRowsChange) {
      const pageRows = this.excludeNonPageRows();
      if(this.allRowsSelected(pageRows)) {
        this.props.onSelectedRowsChange([]);
      } else {
        this.props.onSelectedRowsChange([...pageRows]);

        //Use the below two lines instead of above line if users should be able to select rows across pages
        //Ctrl+F SelectAcrossPages for other places in this file that would need to be changed

        //const pagingStartIndex = this.getPagingStartIndex();
        //this.props.onSelectedRowsChange([...this.props.data.filter((_, index) => index >= pagingStartIndex)]);
      }
    }
  }

  handleRowCheckboxClick = (row: T): void => {
    if(this.props.selectedRows !== undefined && this.props.onSelectedRowsChange) {
      const newSelectedRows = [...this.props.selectedRows];
      if(this.isRowSelected(row)) {
        const index = newSelectedRows.findIndex(selectedRow => this.rowsEqual(selectedRow, row));
        if(index >= 0) {
          newSelectedRows.splice(index, 1);
        }
      } else {
        newSelectedRows.push(row);
      }
      this.props.onSelectedRowsChange(newSelectedRows);
    }
  }

  handleRowRadioClick = (row: T): void => {
    if(this.props.selectedRows !== undefined && this.props.selectedRows.length === 1 && this.props.onSelectedRowsChange) {
      const oldSelectedRow = this.props.selectedRows[0];
      if(!this.rowsEqual(row, oldSelectedRow)) {
        this.props.onSelectedRowsChange([row]);
      }
    }
  }

  getSelectionColumnPosition = (): "first" | "last" => {
    return this.props.selectionColumnPosition === undefined ? "first" : this.props.selectionColumnPosition;
  }

  getHeaders() {
    const element = this.state.element;
    const formattedHeaders: JSX.Element[] = [];

    let selectionColumn: JSX.Element | undefined = undefined;
    if (this.rowSelectionEnabled()) {
      selectionColumn = <th key={"<<selectioncolumnheader>>"} className={element('table-header-cell')}>
        {(this.isMultiSelect() && this.props.selectionColumnHeader === undefined) ?
          <><Checkbox checked={this.allRowsSelected()} onChange={this.handleSelectAllRowsClick} label="ALL" /></> :
          // <><input type="checkbox" onChange={this.handleSelectAllRowsClick} checked={this.allRowsSelected()} className={element('checkbox" /> ALL</> :
          <>{this.props.selectionColumnHeader ?? ""}</>
        }
      </th>
    }
    
    this.state.customGridColumns.forEach((header, key) => {
      formattedHeaders.push(
        <th key={key} className={element('table-header-cell')}>
          <div className={element('sort-col')}>
            {header.headerName}
            {this.props.enableSorting &&
              <button
                className={element('sort-btn')}
                onClick={() => this.onSortColumn(header.field)}
              >
                <ImportExportIcon />
              </button>
            }
          </div>
        </th>
      );
    });

    if(selectionColumn !== undefined) {
      if(this.getSelectionColumnPosition() === 'first') {
        formattedHeaders.unshift(selectionColumn);
      } else {
        formattedHeaders.push(selectionColumn);
      }
    }

    return formattedHeaders;
  }

  onSortColumn(fieldName: string) {
    this.setState((prevState) => {
      const { customGridRowData, sortAsc, lastSortedField } = prevState;
      let newSortAsc = !sortAsc;
      if (lastSortedField !== fieldName) {
        newSortAsc = true;
      }

      const updatedCustomGridRowData = lodash.cloneDeep(customGridRowData).sort((row1: T, row2: T) => {
        let sortValue = 0;

        if (newSortAsc) {
          if (
            row1[fieldName].toString().toLocaleLowerCase() <
            row2[fieldName].toString().toLocaleLowerCase()
          ) {
            sortValue = -1;
          }

          if (
            row1[fieldName].toString().toLocaleLowerCase() >
            row2[fieldName].toString().toLocaleLowerCase()
          ) {
            sortValue = 1;
          }
        } else {
          if (
            row1[fieldName].toString().toLocaleLowerCase() <
            row2[fieldName].toString().toLocaleLowerCase()
          ) {
            sortValue = 1;
          }

          if (
            row1[fieldName].toString().toLocaleLowerCase() >
            row2[fieldName].toString().toLocaleLowerCase()
          ) {
            sortValue = -1;
          }
        }

        return sortValue;
      });

      return {
        customGridRowData: updatedCustomGridRowData,
        sortAsc: newSortAsc,
        lastSortedField: fieldName
      };
    });
  }

  getDataElement = (column: ColumnHeader<T>, data: T, rowIndex: number): JSX.Element => {
    const customContent = (column.customContent && data !== undefined) ? column.customContent(data, rowIndex, column) : undefined;
    return ((data === undefined || data === null) ? <></> :
           (customContent !== undefined ? customContent :
           (column.toStringFunc ? <>{column.toStringFunc(data[column.field])}</> :
           (<>{data[column.field]}</>))));
  }

  getPageRows = (): {pageRows: T[], itemCount: number} => {
    const { filter, searchValue, customGridColumns } = this.state;
    let filterField = '';
    let filterOperation = '';
    let filterValue = '';
    if(filter) {
      const arr = filter.split(',');
      filterField = arr[0];
      filterOperation = arr[1];
      filterValue = arr[2];
    }

    const pagingStartIndex = this.getPagingStartIndex();
    const mappedRows: (T | null)[] = this.state.customGridRowData.map((data, index) => {
      if(data !== undefined && data !== null && index >= pagingStartIndex) {
        //if not a child or a child that is supposed to follow the same form
        
        //handle search
        if(searchValue) {
          let searchSuccess = false;
          customGridColumns.forEach((header) => {
            if (data[`${header.field}`].toString().toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
              searchSuccess = true;
            }
          });

          if (!searchSuccess) {
            return null;
          }
        }

        //handle filter
        if(filter && filterValue) {
          if(filterOperation === 'LT') {
            if(data[filterField].toString().toLocaleLowerCase() >= filterValue){
              return null;
            }
          }
          if(filterOperation === 'EQ') {
            if(data[filterField].toString().toLocaleLowerCase() != filterValue){
              return null;
            }
          }
          if(filterOperation === 'GT') {
            if(data[filterField].toString().toLocaleLowerCase() <= filterValue){
              return null;
            }
          }        
        }
      }

      return data;
    });    

    const safeMappedRows: T[] = [];
    mappedRows.forEach((row) => {
      if(row !== null) {
        safeMappedRows.push(row);
      }        
    });

    const page = this.state.currentPage;
    const pageSize = this.state.pageSize;
    const itemCount = safeMappedRows.length;

    const startIndex = (page-1)*pageSize + pagingStartIndex;
    let endIndex = startIndex + pageSize - 1;
    if(endIndex > itemCount - 1) {
      endIndex = itemCount - 1;
    }

    const pageRows: T[] = [];

    //Add rows that are exempt from paging
    for(let i = 0; i < pagingStartIndex; i++) {
      pageRows.push(safeMappedRows[i]);
    }

    //Add rows for current page
    for(let i = startIndex; i <= endIndex; i++) {
      pageRows.push(safeMappedRows[i]);
    }
    return {pageRows, itemCount: itemCount - pagingStartIndex};
  }

  shouldShowEmptyTableDisplay = (): boolean => {
    return (this.state.customGridRowData.length === 0 && this.props.emptyTableMessage !== undefined);
  }

  getEmptyTableDisplay = (): {pageItems: JSX.Element[], itemCount: number} => {
    const element = this.state.element;
    const numCols = this.state.customGridColumns.length + (this.rowSelectionEnabled() ? 1 : 0);

    //still need a "key" even though it's only one row because it's ultimately being rendered via an array so React will still get mad
    const row = <tr className={element("table-body-row")} key="onlyrow">
      <td colSpan={numCols} className={element("table-body-cell")}>{this.props.emptyTableMessage}</td>
    </tr>
    return { pageItems: [row], itemCount: 1 };
  }

  getRowData = (): {pageItems: JSX.Element[], itemCount: number} => {
    const element = this.state.element;
    if(this.shouldShowEmptyTableDisplay()) {
      return this.getEmptyTableDisplay();
    }

    const {pageRows, itemCount} = this.getPageRows();
    const selectionColumnFirst: boolean = this.getSelectionColumnPosition() === 'first';

    const pagingStartIndex = this.getPagingStartIndex();
    const pageItems: JSX.Element[] = pageRows.map((data, rowIndex) => {
      const mappedTableCells = this.state.customGridColumns.map((columnData, key) => {
        return (
          <td className={element("table-body-cell")} key={key}>
            {this.getDataElement(columnData, data, rowIndex)}
          </td>
        );      
      });
      
      if(this.rowSelectionEnabled()) {
        const selectionCell = <td key="<<selectioncell>>" className={element("table-body-cell")}>
          {(data !== undefined && rowIndex >= pagingStartIndex) &&
            <>
              {this.isMultiSelect() ?
                <Checkbox checked={this.isRowSelected(data)} onChange={() => this.handleRowCheckboxClick(data)} label="" /> :
                <Radio checked={this.isRowSelected(data)} onChange={() => this.handleRowRadioClick(data)} group={this.state.radioName} />
              }
            </>
          }
        </td>
        if(selectionColumnFirst) {
          mappedTableCells.unshift(selectionCell);
        } else {
          mappedTableCells.push(selectionCell);
        }
      }

      return(
        <tr className={element("table-body-row")} key={rowIndex}>
          {mappedTableCells}
        </tr>
      );
    });

    return {pageItems, itemCount};
  }
  onPageChange = (page: number) => {
    const {totalPages} = this.state;

    if (page > totalPages) {
      page = totalPages;
    }
    else if (page < 1) {
      page = 1;
    }
    if(page !== this.state.currentPage) {
      this.setState({currentPage: page});

      //Clear selections on page change to avoid confusion
      //Remove this code block if users should be able to select rows across pages
      //Ctrl+F SelectAcrossPages for other places in this file that would need to be changed
      if(this.props.onSelectedRowsChange) {
        this.props.onSelectedRowsChange([]);
      }
    }
  }

  onFilterButtonClick() {
    //fix css bugs to hide/show filter menu
  }

  getMainFilterOptions() {
    const mappedFilters = this.state.customGridColumns.map((columnData, key) => {
      return (
        <option key={key} value={columnData.field}>{columnData.headerName}</option>
      );      
    });
    return (
      <select defaultValue='default' onChange={(e) => this.onDataChange(e.target.value, 'filterField')}>
        <option key='default'></option>
        {mappedFilters}
      </select >
    );
  }

  onDataChange(data: MainDataGridState<T>[typeof field], field: keyof MainDataGridState<T>) {
    this.setState({
      [field]: data
    } as any);
  }

  onFilterClick() {
    const filter = `${this.state.filterField},${this.state.filterOperation},${this.state.filterValue}`;
    this.setState({filter: filter});
  }


  render() {
    const block = this.state.block;
    const element = this.state.element;

    const {
      isLoading, pageSize
    } = this.state;
    const {
      enableSearching, enableFiltering, enablePaging
    } = this.props;

    const {pageItems, itemCount} = this.getRowData();
    let numPages = parseInt(itemCount / pageSize, 10);
    numPages += itemCount % pageSize != 0 ? 1 : 0;
    if (numPages <= 0 || numPages === null || numPages === undefined) {
      numPages = 1;
    }

    

    return (
      <div className={block()}>
        {/* <div> Option with React data-grid from MUI:  https://mui.com/components/data-grid/ </div>
        <div style={{ height: 500, width: '100%' }}>
          <DataGrid
            rows={this.props.data}
            columns={this.state.muiGridColumns}
            pageSize={this.state.pageSize}
            onPageSizeChange={(newPageSize) => this.setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </div> */}
        {/* <div> Option with MaterialTable (based on MUI):  https://material-table.com/#/ </div> */}
        {/* <MaterialTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          options={{
            headerStyle: {
              borderTop: '1px solid #979797',
            },
            paging: false,
            rowStyle: (rowData) => ({
              border: 'none',
              borderTop: '2px solid #979797',
            }),
          }}
          title={
            
          }
          style={{
            boxShadow: 'none',
          }}
        /> */}
        <table className={element('table table table-striped')}>
          {this.props.showHeaders !== false &&
            <thead>
              {(enableFiltering || enableSearching) &&
                <tr className={element('table-header-row')}>
                  <th className={element('table-header-cell')} colSpan={this.state.customGridColumns.length}>
                    {enableFiltering &&
                      <div className={element('table--header-filter-container')}>
                        <button
                          className={element('table--header-filter--button')}
                          onClick={()=> this.onFilterButtonClick()}
                        >
                          <span className='fa fa-filter'></span>
                        </button>
                        <div className={element('table--header-filter-content')}>
                          {this.getMainFilterOptions()}
                          <select defaultValue='default' onChange={(e) => this.onDataChange(e.target.value, 'filterOperation') }>
                            <option key='default'></option>
                            <option key='less' value='LT'> {'<'} </option>
                            <option key='equal' value='EQ'> {'='} </option>
                            <option key='greater' value='GT'> {'>'} </option>
                          </select >
                          <input className={element('table--header-filter-content--input')} onChange={(e) => this.onDataChange(e.target.value, 'filterValue')} />
                          <button onClick={() => this.onFilterClick()}>Filter</button>
                        </div>
                      </div>
                    }
                    {enableSearching &&
                      <div className={element('table--header-search-container')}>
                        <input className={element('table--header-filter-content--input')} placeholder='Search' onChange={(e) => this.onDataChange(e.target.value, 'searchValue')} />
                      </div>
                    }
                  </th>             
                </tr>
              }
              <tr className={element('table-header-row')}>
                {this.getHeaders()}
              </tr>
            </thead>
          }
          <tbody>{pageItems}</tbody> 
        </table>
        {(enablePaging && !this.shouldShowEmptyTableDisplay()) &&
          <div className={element('pagination-container')}>
            <Pagination
              pageSize={pageSize}
              page={this.state.currentPage - 1}
              totalItems={itemCount}
              onPageChange={page => this.onPageChange(page + 1)} 
              onPageSizeChange={pageSize => this.setPageSize(pageSize)}
            />
          </div>
        }

        <Overlay show={isLoading}>
          <i className="overlay--spinner-eclipse"></i>
        </Overlay>
      </div>      
    );
  }
}

export default DataGrid;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bemify from './utils/bemUtils';
import Modal from './framework/modal/modal';

/**
 * Represents an error boundary that globally catches all errors.
 */
class ErrorBoundary extends Component {
  /**
   * Initializes a new instance of the ErrorBoundary component.
   * @param {Object} props The component properties.
   */
  constructor(props) {
    super(props);

    this.state = {
      error: {},
      errorInfo: {
        componentStack: {}
      },
      showError: false
    };
  }

  /**
   * Executes when an error is thrown.
   * @param {Object} error The error.
   * @param {Object} errorInfo Additional information related to the error.
   */
  componentDidCatch(error, errorInfo) {
    this.setState(() => ({
      error: error,
      showError: true,
      errorInfo: errorInfo
    }));
  }

  _onCloseErrorModal = () => {
    this.setState(() => ({ showError: false }));
  };

  /**
   * Renders the component.
   */
  render() {
    const {
      error,
      showError
    } = this.state;
    const { children } = this.props;
    const [block, element] = bemify('error-modal');
  
    return (
      <>
        {
          showError &&
          <Modal show={showError}>
            <div className={block()}>
              <div className={element('header')}>
                <h3 className={element('title')}>Error</h3>
                <button
                  className={element('close')}
                  onClick={this._onCloseErrorModal}>
                  <span className={element('icon')}></span>
                </button>
              </div>
              <div className={element('body')}>
                <p>{error.message}</p>
              </div>
              <div className={element('footer')}>
                <button
                  className={element('ok')}
                  type="button"
                  onClick={this._onCloseErrorModal}>
                  ok
                </button>
              </div>
            </div>
          </Modal>
        }
        { children }
      </>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;

import React from 'react';
import PropTypes from 'prop-types';
import bemify from '../../utils/bemUtils';
import Overlay from '../overlay/overlay';

/**
 * Represents a spinner loader animation.
 * @param {Object} props The component properties.
 */
const SpinnerLoader = (props) => {
  const { show } = props;
  const [block, _] = bemify('spinner-loader');

  return (
    <Overlay show={show}>
      <div className={block()}></div>
    </Overlay>
  );
};

SpinnerLoader.propTypes = {
  show: PropTypes.bool.isRequired
};

export default SpinnerLoader;

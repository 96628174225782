import React, { useEffect } from 'react';
import bemify from '../../general/bemUtils';
import Dropdown from '../controls/dropdown/dropdown';
import { CleanButton } from '../controls/cleanButton/cleanButton';

const allPageSize: number = Number.MAX_SAFE_INTEGER;

const defaultPageSizeOptions = [10, 25, 50, 100, 250, 500, allPageSize]
const pageSizeOptionToStr = (option: number): string => {
  return option === allPageSize ? 'All' : `${option}`;
}
const pageToStr = (page: number): string => {
  return `Page ${page + 1}`;
}

type PaginationProps = {
  totalItems: number,
  pageSize: number,
  page: number, //zero based
  onPageChange: (page: number) => void,
  onPageSizeChange: (pageSize: number) => void,
  pageSizeOptions?: number[]
}

export const Pagination = ({
  totalItems,
  pageSize,
  page,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions: rawPageSizeOptions
}: PaginationProps) => {
  const pageSizeOptions = rawPageSizeOptions === undefined ? defaultPageSizeOptions : rawPageSizeOptions;

  const totalPages = Math.ceil(totalItems / pageSize);
  const pageOptions = Array.from(Array(totalPages).keys());
  const firstItemNum = page * pageSize + 1;
  const rawLastItemNum = (page + 1) * pageSize;
  const lastItemNum = rawLastItemNum > totalItems ? totalItems : rawLastItemNum;

  useEffect(() => {
    if(firstItemNum > lastItemNum) {
      onPageChange(0);
    }
  }, [firstItemNum, lastItemNum, onPageChange]);

  const leftButtonDisabled = page <= 0;
  const rightButtonDisabled = page >= totalPages - 1;

  const [block, element] = bemify('pagination');
  return <div className={block()}>
    <div className={element('left-side')}>
      <div className={element('items-display-area')}>
        {firstItemNum} - {lastItemNum} of {totalItems}
      </div>
      <div className={element('per-page-area')}>
        <span>Show per page</span>
        <Dropdown<number> items={pageSizeOptions}
                          value={pageSize}
                          onChange={item => item === null ? null : onPageSizeChange(item)}
                          toStringFunc={pageSizeOptionToStr}
                          className={element('dropdown')} />
      </div>
    </div>
    <div className={element('right-side')}>
      <CleanButton disabled={leftButtonDisabled} onClick={() => onPageChange(page - 1)} className={element('page-button')}><i className="fa fa-chevron-left" /></CleanButton>
      <Dropdown<number> items={pageOptions}
                        value={page}
                        onChange={item => item === null ? null : onPageChange(item)}
                        toStringFunc={pageToStr}
                        className={element('dropdown')} />
      <CleanButton disabled={rightButtonDisabled} onClick={() => onPageChange(page + 1)} className={element('page-button')}><i className="fa fa-chevron-right" /></CleanButton>
    </div>
  </div>
}

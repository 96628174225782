import React, { ReactNode } from 'react';
import Utils from '../../../general/utils';

/**
 * A button with default styling removed, so it looks like plain text with a pointer cursor added
 */
type CleanButtonProps = {
  children?: ReactNode
  className?: string
  onClick?: () => void,
  role?: React.AriaRole,
  id?: string,
  disabled?: boolean
}
export const CleanButton = (props: CleanButtonProps) => {
  const { children, className, ...restProps } = props;
  return <button className={Utils.css("cleanbutton", className)}
                 {...restProps}>
    {children}
  </button>
}


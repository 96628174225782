import React, { useRef, useEffect, useCallback } from 'react';

type ClickAwayListenerProps = {
  children: React.ReactNode,
  onClickAway: (evt: MouseEvent) => void
}

/**
 * Allows detection of clicks outside of an element.
 */
const ClickAwayListener = ({ children, onClickAway }: ClickAwayListenerProps) => {
  const clickAwayListenerRef = useRef<HTMLDivElement>(null);

  const _onDocumentClick = useCallback((e: MouseEvent) => {
    // Check if the user clicked outside of our client area.
    if(clickAwayListenerRef.current !== null) {
      if (!clickAwayListenerRef.current.contains(e.target as Node)) {
        onClickAway && onClickAway(e);
      }
    }
  }, [onClickAway]);

  useEffect(() => {
    document.addEventListener('click', _onDocumentClick);

    return () => {
      document.removeEventListener('click', _onDocumentClick);
    };
  }, [_onDocumentClick]);

  return (
    <div
      ref={clickAwayListenerRef}>
      { children }
    </div>
  );
};

export default ClickAwayListener;

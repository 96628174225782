import { AuthenticationResult } from '@azure/msal-common';
import axios, { AxiosRequestConfig } from 'axios';

export type RestResponse<T> = {
  data: T,
  headers: any,
  statusCode: number,
  statusText: string
}

/**
 * Represents a HTTP REST client.
 */
class Rest {
  private static initialized: boolean = false;

  /**
   * Initializes the rest api.
   */
  public static async init(token: AuthenticationResult): Promise<void> {
    if(!Rest.initialized) {
      Rest.initialized = true;
      const jwt = token.accessToken;

      // Setup axios to pass data each request.
      axios.interceptors.request.use((config) => {
        // EXAMPLE INTERCEPTOR FOR PASSING JWT.
        // const jwt = '';
        // config.headers['Authorization'] = `Bearer ${jwt}`;
        if(jwt) {
          config.headers['Authorization'] = `Bearer ${jwt}`;
        }

        return config;
      });
    }
  }

  /**
   * Issues an HTTP GET request.
   * @param {String} url The url to the api call.
   * @returns Promise 
   */
  public static get<T>(url: string, headers?: any): Promise<RestResponse<T>> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'GET',
      headers: headers || {}
    };
    return this._request(options) as Promise<RestResponse<T>>;
  }

  /**
   * Issues an HTTP POST request.
   * @param {String} url The url to the api call.
   * @param {Object} data The data to pass to the api call.
   * @returns Promise
   */
  public static post<T>(url: string, data: Object, headers?: any): Promise<RestResponse<T>> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'POST',
      data: data || {},
      headers: headers || {}
    };
    return this._request(options) as Promise<RestResponse<T>>;
  }

  /**
   * Issues an HTTP PUT request.
   * @param {String} url The url to the api call.
   * @param {Object} data The data to pass to the api call.
   * @returns Promise
   */
  public static put<T>(url: string, data: Object): Promise<RestResponse<T>> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'PUT',
      data: data || {}
    };
    return this._request(options) as Promise<RestResponse<T>>;
  }

  /**
   * Issues an HTTP PATCH request.
   * @param {String} url The url to the api call.
   * @param {Object} data The data to pass to the api call.
   * @returns Promise
   */
  public static patch<T>(url: string, data: Object): Promise<RestResponse<T>> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'PUT',
      data: data || {}
    };
    return this._request(options) as Promise<RestResponse<T>>;
  }

  /**
   * Issues an HTTP DELETE request.
   * @param {String} url The url to the api call.
   * @returns Promise
   */
  public static delete<T>(url: string, headers?: any): Promise<RestResponse<T>> {
    const options: AxiosRequestConfig = {
      url: url,
      method: 'DELETE',
      headers: headers || {}
    };
    
    return this._request(options) as Promise<RestResponse<T>>;
  }

  /**
   * Expands an array of arguments as parameters to a function.
   * @param {Function} callback The callback to receive the parameters.
   * @returns Promise
   */
  public static expandToArgs<T, R>(callback: (...args: T[]) => R): (array: T[]) => R {
    return axios.spread(callback);
  }

  private static _request<T>(options: AxiosRequestConfig): Promise<RestResponse<T>> {
    return new Promise<RestResponse<T>>((resolve, reject) => {
      axios(options)
        .then((res) => {
          resolve({
            data: res.data,
            headers: res.headers,
            statusCode: res.status,
            statusText: res.statusText
          });
        })
        .catch((error) => {
          if (error.response) {
            // Server received the request and responded with
            // an error outside of the 2xx range (e.g. 4xx, 5xx).
            reject({
              response: {
                data: error.response.data,
                headers: error.response.headers,
                statusCode: error.response.status
              }
            });
          }
          else if (error.request) {
            // The request was made, but no response was received.
            reject({
              request: error.request
            });
          }
          else {
            // There was an issue with setting up the request.
            reject({
              message: error.message
            });
          }
        });
    });
  }
}

export default Rest;

import React from 'react';
import bemify from '../../../general/bemUtils';

type CheckboxSize = 'small' | 'normal';
type CheckboxProps = {
  checked: boolean,
  onChange: (checked: boolean) => void,
  label?: string,
  size?: CheckboxSize
}
/**
 * Typescript port of framework's checkbox.jsx, modified to be properly bound
 */
const Checkbox = (props: CheckboxProps) => {
  const {
    checked,
    label,
    size: optionalSize,
    onChange
  } = props;
  const [block, element] = bemify('checkbox');
  const size: CheckboxSize = optionalSize === undefined ? 'normal' : optionalSize;

  return (
    <label className={block(size)}>
      {label !== undefined &&
        <span className={element('label')}>{label}</span>
      }
      <input
        className={element('input')}
        checked={checked}
        type="checkbox"
        onChange={e => onChange(e.target.checked)} />
      <span className={element('checkbox', size)}></span>
    </label>
  );
};

export default Checkbox;

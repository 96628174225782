import React, { createContext, useState } from "react"

//What buttons to show on the prompt
export enum PromptMode {
    Ok,
    YesNo,
    YesNoCancel
}

/**
 * A modal window for showing alerts and prompts to the user
 */
type PromptModalWindowProps = {
    open: boolean,
    title?: string,
    prompt?: string | JSX.Element,
    yesLabel?: string,
    noLabel?: string,
    cancelLabel?: string,
    mode?: PromptMode, //defaults to YesNo
    onResponse: (yes: boolean | undefined) => void,
    content?: JSX.Element //overrides everything else
}
const PromptModalWindow = (props: PromptModalWindowProps) => {
    const mode: PromptMode = props.mode === undefined ? PromptMode.YesNo : props.mode;
    const handleButtonClick = (response: boolean | undefined): void => {
        props.onResponse(response);
    };
    return <>
        {props.open &&
            <div className='promptmodal'>
                {(props.content !== undefined) ?
                    props.content :
                    <div className='promptmodal__panel'>
                        {props.title &&
                            <h3 className='promptmodal__title'>{props.title}</h3>
                        }
                        {props.prompt &&
                            <div className='promptmodal__prompt'>{props.prompt}</div>
                        }
                        <div className={`promptmodal__buttons${mode === PromptMode.YesNoCancel ? ' promptmodal__reverse-button-order' : ''}`}>
                            {(mode === PromptMode.YesNoCancel) &&
                                <button className='promptmodal__cancel-button' onClick={() => handleButtonClick(undefined)}>
                                    {props.cancelLabel ?? "Cancel"}
                                </button>
                            }
                            {(mode !== PromptMode.Ok) &&
                                <button className='promptmodal__no-button' onClick={() => handleButtonClick(false)}>
                                    {props.noLabel ?? "No"}
                                </button>
                            }
                            <button className={mode === PromptMode.Ok ? 'promptmodal__ok-button' : 'promptmodal__yes-button'} onClick={() => handleButtonClick(true)}>
                                {props.yesLabel ?? (mode === PromptMode.Ok ? "OK" : "Yes")}
                            </button>
                        </div>
                    </div>
                }
            </div>
        }
    </>
}

//Context for allowing any descendant to set the props of the prompt modal
export const PromptContext = createContext<(modalProps: PromptModalWindowProps) => void>(() => {});

type WithPromptModalProps = {
  children: React.ReactNode
}

/**
 * Component for placing the prompt modal into the DOM
 */
export const WithPromptModal = (props: WithPromptModalProps) => {
  const [modalProps, setModalProps] = useState<PromptModalWindowProps>({ open: false, onResponse: () => {} });
  return <PromptContext.Provider value={setModalProps}>
    <PromptModalWindow {...modalProps} />
    {props.children}
  </PromptContext.Provider>
}


import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { MsalAuthenticationTemplate, MsalProvider, useAccount, useMsal } from '@azure/msal-react';
import { AuthenticationResult, Configuration, InteractionType, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { getB2cConfigExperience, getMsalConfig } from './authConfig';
import Rest from '../../general/rest';
import { useSettings } from '../../hooks/useSettings';
import { EnvSettings } from '../../services/settingsService';

const getAuthRequest = (settings: EnvSettings): RedirectRequest => ({
  scopes: getMsalConfig(settings).auth.scopes,
});

export const TokenContext = createContext<AuthenticationResult | undefined>(undefined);

type ChildrenProps = {
  children: ReactNode
}

/**
 * A component inside which nothing will render until a valid AD B2C authentication exists, and which redirects
 * the user to the authentication workflow if they are not authenticated.
 */
const Authwall = (props: ChildrenProps) => {
  const settings = useSettings();
  const [pca, setPca] = useState<PublicClientApplication | undefined>(undefined);
  
  const isBrowser = typeof window !== "undefined";
  const getWindowLocation = () => {if(isBrowser){return window.location.search} else {return '';}};
  const queryParams = new URLSearchParams(getWindowLocation());
  const authExperience = queryParams.get("authExperience")??"default";

  //Fetch the AD B2C configuration details and create the public client application object
  useEffect(() => {
    const getConfig = async (): Promise<Configuration> => {
      const b2cConfig = getB2cConfigExperience(settings, authExperience)
      return b2cConfig    
    };
    const fetchConfig = async (): Promise<void> => {
      const config = await getConfig();
      if(config) {
        const newPca = new PublicClientApplication(config);
        await newPca.initialize(); //prevents race condition by ensuring msal is done doing its thing
        setPca(newPca);
      }
    }
    fetchConfig();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const authRequest = getAuthRequest(settings);
  return pca === undefined ? <></> : <MsalProvider instance={pca}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}
                                authenticationRequest={authRequest}>
      <MsalContainer>
        {props.children}
      </MsalContainer>
    </MsalAuthenticationTemplate>
  </MsalProvider>
};

/**
 * Inner component for authwall, enabling the use of the useMsal hook.
 */
const MsalContainer = (props: ChildrenProps) => {
  const settings = useSettings();
  const [scopes] = useState<string[]>(getAuthRequest(settings).scopes);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [token, setToken] = useState<AuthenticationResult | undefined>(undefined);

  useEffect(() => {
    const fetchToken = async (): Promise<void> => {
      if(account && !token) {
        await instance.handleRedirectPromise(); //prevents race condition by ensuring msal is done doing its thing
        try {
          const newToken: AuthenticationResult = await instance.acquireTokenSilent({
            scopes: scopes,
            account: account,
            
          });
          if(newToken) {
            setToken(newToken);
            Rest.init(newToken);
          }
        } catch {
          await instance.acquireTokenRedirect({
            scopes: scopes,
            account: account,
          });
        }
      }
    }
    fetchToken();
  }, [instance, account, token, scopes]);

  return token === undefined ? <></> : <TokenContext.Provider value={token}>
    {props.children}
  </TokenContext.Provider>
}

export default Authwall;
/**
 * Sets the document body and html element overflow to hidden to prevent scrolling.
 */
export function setDocumentOverflowToHidden() {
  document.body.style.overflow = 'hidden';
  document.documentElement.style.overflow = 'hidden';
}

/**
 * Sets the document body and html element overflow back to its initial value to allow scrolling.
 */
export function setDocumentOverflowToInitial() {
  document.body.style.overflow = '';
  document.documentElement.style.overflow = '';
}

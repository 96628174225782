import React from 'react';
import bemify from '../../../general/bemUtils';

type RadioSize = 'small' | 'normal';
type RadioProps = {
  checked: boolean,
  group: string,
  onChange: (value: string) => void,
  value?: string,
  label?: string,
  size?: RadioSize
}
/**
 * Typescript port of framework's radio.jsx, modified to be properly bound
 */
const Radio = (props: RadioProps) => {
  const {
    checked,
    group,
    label,
    size: optionalSize,
    value,
    onChange
  } = props;
  const [block, element] = bemify('radio');
  const size: RadioSize = optionalSize === undefined ? 'normal' : optionalSize;

  return (
    <label className={block(size)}>{label}
      <input
        className={element('input')}
        checked={checked}
        name={group}
        type="radio"
        value={value}
        onChange={e => onChange(e.target.value)} />
      <span className={element('radio', size)}></span>
    </label>
  );
};

export default Radio;

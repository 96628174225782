import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropdown from '../controls/dropdown/dropdown';
import DateField from '../controls/dateField/dateField';
import { GridData } from '../../models/gridData';
import DataGrid, { ColumnHeader } from '../dataGrid/dataGrid';
import { ExternalLocationStatus, ExternalLocationStatusId, MappingType, statusTypes } from '../../models/lookups';
import { MatchedLocationsModal } from '../matchedLocationsModal/matchedLocationsModal';
import { usePrompt } from '../../hooks/usePrompt';
import { useToast } from '../../hooks/useToast';
import Utils from '../../general/utils';
import useMappingService from '../../services/mappingService';
import bemify from '../../general/bemUtils';
import { DarkButton, LightButton } from '../controls/button/button';
import Tab, { TabInfo } from '../tab/tab';
import DebouncedTextbox from '../debouncedTextbox/debouncedTextbox';

/**
 * Filters data by text
 */
const filterDataByText = (data: GridData[], filter: string): GridData[] => {
  const trimmed = filter.trim().toLowerCase();
  const filterBy: (keyof GridData)[] = [
    "address1",
    "city",
    'coverKey',
    'externalId',
    'locKey',
    'name',
    'placeKey',
    'state',
    'zip'
  ];
  const filtered = trimmed === '' ? data : data.filter(item => {
    let include: boolean = false;
    for(const field of filterBy) {
      const fieldValue = `${item[field]}`.toLowerCase();
      if(fieldValue.includes(trimmed)) {
        include = true;
        break;
      }
    }
    return include;
  });
  return filtered;
}

/**
 * Converts JSON date string to date string for grid
 */
const toGridDateString = (dateStr: string): string => {
  const dt = new Date(dateStr);
  let gridStr = "";
  if(!isNaN(dt.getTime())) {
    gridStr = dt.toLocaleDateString();
  }
  return gridStr;
}

/**
 * Search subset of the LocationMappingByTypeProps
 */
type SearchParams = {
  mappingTypeId: string | null,
  uploadStartDate: Date | null,
  uploadEndDate: Date | null
}

/**
 * Represents one of the actions the user can perform on a selected row. The conditions property
 * contains a list of statuses for which the action is permitted.
 */
type ConditionalButton = {
  text: string,
  action: () => void,
  isDarkButton: boolean,
  conditions?: ExternalLocationStatusId[] | "any",
  isUploadButton?: boolean,
  id?: string
}

/**
 * This component is responsibe for fetching the mapping types for the logged-in user and prompting the
 * user to select one. Once a valid mapping type is chosen, <LocationMappingByType> renders the rest.
 */
const LocationMapping = () => {
  //The mapping type lookup values
  const [mappingTypeOptions, setMappingTypeOptions] = useState<MappingType[]>([]);

  //The status lookup values (currently used only for their descriptions)
  const [statusOptions, setStatusOptions] = useState<ExternalLocationStatus[] | undefined>(undefined);

  //The user-selected mapping type
  const [selectedMappingType, setSelectedMappingType] = useState<string | null>(null);

  //The user-selected upload date range
  const [uploadStartDate, setUploadStartDate] = useState<Date | null>(null);
  const [uploadEndDate, setUploadEndDate] = useState<Date | null>(null);

  //Bundles the search options into a single object, which, because of how useEffect works, makes it easier
  //in React to provide a user-initiated refresh
  const toSearchProps =  (): SearchParams => {
    return {
      mappingTypeId: selectedMappingType,
      uploadStartDate: uploadStartDate,
      uploadEndDate: uploadEndDate
    }
  }

  //The search options
  const [searchParams, setSearchParams] = useState<SearchParams>(toSearchProps());


  //Get services from ancestor contexts. These services are designed to be unchanging so it is safe to put them
  //in dependency arrays to prevent React warnings.

  //Toasts
  const toast = useToast();

  //The API service
  const mappingService = useMappingService();


  //Fetch the mapping types to go in the filter dropdown
  useEffect(() => {
    const fetchMappingTypeOptions = async (): Promise<void> => {
      if(mappingTypeOptions.length === 0) {
        try {
          const fetchedOptions = await mappingService.getMappingTypes();
          if(fetchedOptions.length > 0) {
            setMappingTypeOptions(fetchedOptions);
          } else {
            toast.error("No mapping type options were returned");
          }
        } catch {
          toast.error("Failed to fetch mapping type options")
        }
      }
    };
    fetchMappingTypeOptions();
  }, [mappingTypeOptions, toast, mappingService]);

  //Fetch the statuses and their descriptions
  useEffect(() => {
    const fetchStatusFilterOptions = async (): Promise<void> => {
      if(statusOptions === undefined) {
        try {
          const statusFilterOptions = await mappingService.getExternalLocationStatuses();
          if(statusFilterOptions.length > 0) {
            setStatusOptions(statusFilterOptions);
          } else {
            toast.error("No status filter options were returned");
          }
        } catch {
          toast.error("Failed to fetch status filter options");
        }
      }
    };
    fetchStatusFilterOptions();
  }, [statusOptions, toast, mappingService]);

  //Get the display value for a given mapping type Guid
  const getMappingTypeDesc = (guid: string): string => {
    const option = mappingTypeOptions.find(val => val.guid === guid);
    return option ? option.description : '';
  }

  //Get the display value for a given status ID
  const getExternalLocationStatusDesc = useCallback((id: ExternalLocationStatusId): string => {
    let desc: string;
    const options = statusOptions ?? [];
    if(id === statusTypes.allRecords) {
      desc = "All Records";
    } else {
      const option = options.find(val => val.externalLocationStatusId === id);
      desc = option ? option.statusValue : '';
    }
    return desc;
  }, [statusOptions]);
  
  //Standard event handlers for dropdowns
  const handleMappingTypeChange = (value: string | null): void => {
    setSelectedMappingType(value);
  }

  //Trigger a search when the button is clicked
  const handleSearchClick = (): void => {
    if(selectedMappingType !== null) {
      setSearchParams(toSearchProps());
    }
  }

  //Disable search unless requirements are met
  const searchEnabled = (selectedMappingType !== null);

  const [block, element] = bemify('locationmapping');
  return <div className={block()}>
    <div className={element('top-card')}>
      <div className={element('title-row')}>
        <div className={element('title-container')}>
          <h3 className={element('title-text')}>Filter Results</h3>
        </div>
      </div>
      <div className={element('filter-row')}>
        <div className={element('filter-control')}>
          <Dropdown<string> items={mappingTypeOptions.map(val => val.guid)}
                            value={selectedMappingType}
                            nullOptionName="Select"
                            onChange={handleMappingTypeChange}
                            toStringFunc={getMappingTypeDesc}
                            legend="Mapping Type" />

        </div>
        <div className={element('control')}>
          <DateField value={uploadStartDate}
                     onChange={setUploadStartDate}
                     legend="Upload Date Start" />
        </div>
        <div className={element('control')}>
          <DateField value={uploadEndDate}
                     onChange={setUploadEndDate}
                     legend="Upload Date End" />
        </div>
      </div>
      <div className={element('filter-button-row')}>
        <DarkButton onClick={handleSearchClick} disabled={!searchEnabled}>Sort Search Results</DarkButton>
      </div>
    </div>
    <LocationMappingByType searchParams={searchParams}
                           getExternalLocationStatusDesc={getExternalLocationStatusDesc} />
  </div>
};


/**
 * This is a large component responsible for:
 * 
 * 1. The high-level layout of the application
 * 2. Prompting the user for filter inputs (status and upload date)
 * 3. Fetching the grid data using the supplied filter inputs and passing this data to the grid component
 * 4. Handling selection of rows within the grid
 * 5. Displaying options the user can perform on selected rows
 * 6. Dispatching actions when a user selects an option
 */
type LocationMappingByTypeProps = {
  searchParams: SearchParams,
  getExternalLocationStatusDesc: (id: ExternalLocationStatusId) => string
}
const LocationMappingByType = ({ searchParams, getExternalLocationStatusDesc }: LocationMappingByTypeProps) => {
  const { mappingTypeId } = searchParams;

  //Ref to the file uploader, used to simulate click events on it so that we can hide it and use a custom-styled button
  //insetad
  const fileRef = useRef<HTMLInputElement>(null);

  //The data from the API, filtered only by the supplied searchParams
  const [rawData, setRawData] = useState<GridData[]>([]);

  //The items selected via the checkboxes in the grid
  const [selectedData, setSelectedData] = useState<GridData[]>([]);

  //The grid row that we are focusing on in the modal. This also controls the modal's open state. If anything other
  //than "undefined", the modal will be open.
  const [openedRow, setOpenedRow] = useState<GridData | undefined>(undefined);


  //Get services from ancestor contexts. These services are designed to be unchanging so it is safe to put them
  //in dependency arrays to prevent React warnings.

  //Prompts
  const prompt = usePrompt();

  //Toasts
  const toast = useToast();

  //The API service
  const mappingService = useMappingService();


  //The index of the selected tab
  const [tabIndex, setTabIndex] = useState(0);

  //The text in the search box
  const [searchText, setSearchText] = useState("");

  //The tab options and their corresponding status IDs
  const tabs: TabInfo<ExternalLocationStatusId>[] = [{
    label: "All Records",
    data: statusTypes.allRecords
  }, {
    label: "Mapped Active",
    data: statusTypes.mappedActive
  }, {
    label: "Mapped Inactive",
    data: statusTypes.mappedInactive
  }, {
    label: "Deleted Records",
    data: statusTypes.deleted
  }, {
    label: "New Records",
    data: statusTypes.new
  }];

  //The selected tab
  const tab: TabInfo<ExternalLocationStatusId> = tabs[tabIndex];

  //The status ID reperesented by the selected tab
  const statusFilter: ExternalLocationStatusId = tab.data === undefined ? statusTypes.allRecords : tab.data;

  //The data locally filtered by the status ID represented by the selected tab as well as search text, if any
  const data = filterDataByText(rawData, searchText).filter(item => statusFilter === statusTypes.allRecords || item.status === statusFilter);

  //Clear selection on tab change
  useEffect(() => {
    setSelectedData([]);
  }, [tabIndex]);

  //Method to take a given status ID and set active the tab that represents that status
  const setStatusFilter = (newStatusFilter: ExternalLocationStatusId) => {
    const newTabIndex = tabs.findIndex(item => item.data === newStatusFilter);
    if(newTabIndex >= 0 && tabs[newTabIndex].data !== undefined) {
      setTabIndex(newTabIndex);
    }
  }

  //Fetch grid data whenever filter options change
  const fetchData = useCallback(async (): Promise<void> => {
    //Unwrap searchParams here so that effect will fire every time searchParams object is changed, which lets user perform a refresh
    //by clicking the search button even without changing search params
    const { mappingTypeId, uploadStartDate, uploadEndDate } = searchParams;
    if(mappingTypeId !== null) {
      try {
        const fetchedData = await mappingService.getExternalLocations(mappingTypeId, null, uploadStartDate, uploadEndDate);
        setRawData(fetchedData);
        setSelectedData([]);
      } catch {
        toast.error("Failed to fetch external location data");
      }
    }
  }, [searchParams, toast, mappingService]);
  useEffect(() => {
    fetchData()
  }, [fetchData]);

  //Configure the data grid columns
  const gridHeaders: ColumnHeader<GridData>[] = [{
    field: "externalId",
    headerName: "External ID"
  }, {
    field: "name",
    headerName: "Location Name"
  }, {
    field: "address1",
    headerName: "Location Address"
  }, {
    field: "city",
    headerName: "City"
  }, {
    field: "state",
    headerName: "State"
  }, {
    field: "zip",
    headerName: "Zip"
  }, {
    field: "coverKey",
    headerName: "CoverKey"
  }, {
    field: "locKey",
    headerName: "LocKey"
  }, {
    field: "placeKey",
    headerName: "PlaceKey"
  }, {
    field: "status",
    headerName: "Map Status",

    //"status" is a number so we want the grid to covert it to its display name
    toStringFunc: (value) => getExternalLocationStatusDesc(value).toUpperCase()
  }, {
    field: "created",
    headerName: "Uploaded",
    toStringFunc: toGridDateString
  }];

  //Configure the buttons to be displayed in the button area, the conditions under which each button is displayed, and
  //the actions performed by each button
  const buttons: ConditionalButton[] = [{
    text: "Find Matches",
    action: () => setOpenedRow(selectedData[0]),
    isDarkButton: false,
    conditions: "any"
  }, {
    id: "unmap",
    text: "Unmap",
    action: async () => {
      try {
        selectedData.forEach(async location => {
          await mappingService.updateMappingStatus(location.externalId, statusTypes.unmapped);
          await fetchData();
        });
      }
      catch {
        toast.error("Error updating mapping status");
      }
    },
    isDarkButton: false,
    conditions: [statusTypes.mappedInactive, statusTypes.mappedActive]
  }, {
    id: "setActive",
    text: "Set Active",
    action: async () => {
      try {
        selectedData.forEach(async location => {
          await mappingService.updateMappingStatus(location.externalId, statusTypes.mappedActive);
          await fetchData();
        });
      }
      catch {
        toast.error("Error updating mapping status");
      }
    },
    isDarkButton: false,
    conditions: [statusTypes.mappedInactive]
  }, {
    text: "Upload CSV",
    action: () => {}, //leave blank, this gets overridden with isUploadButton
    isDarkButton: false,
    isUploadButton: true
  }, {
    text: "Download as CSV",
    action: async () => {
      try {
        const file = await mappingService.getCsvData(mappingTypeId!, (selectedData.length === 0 ? data : selectedData).map(item => item.externalId));
        Utils.downloadFile(file, "export.csv");
      } catch {
        toast.error("Failed to get file for download");
      }
    },
    isDarkButton: true
  }, {
    id: "delete",
    text: "Delete Record",
    action: () => handleAnyDelete(),
    isDarkButton: false,
    conditions: [statusTypes.new, statusTypes.unmapped, statusTypes.mappedInactive, statusTypes.deleted]
  }];

  //Sub-handler for permanent deletion
  const handlePermanentlyDelete = async (): Promise<void> => {
    if (selectedData.length > 0) {
      if(await prompt.prompt(`Do you want to permanently delete the selected ${selectedData.length} row${selectedData.length === 1 ? '' : 's'}?`)) {
        try {
          const promises = selectedData.map(async item => await mappingService.deleteMappingLocation(mappingTypeId!, item.externalId));
          await Promise.all(promises);
          setStatusFilter(statusTypes.deleted)
          await fetchData();
        } catch {
          toast.error("Failed to permanently delete");
        }
      }
    } else {
      toast.error("No rows selected");
    }
  };

  //Sub-handler for soft deletion
  const handleMarkDelete = async (dataToDelete: GridData[]) => {
    try {
      const promises = dataToDelete.map(async item => await mappingService.updateMappingStatus(item.externalId, 5, item?.locKey));
      await Promise.all(promises);
      await fetchData();
    } catch {
      toast.error("Failed to mark for deletion");
    }
  }

  //Handler for delete button, which routes to either the soft delete or permanent delete sub-handler
  const handleAnyDelete = async () => {
    if(selectedData.every(item => item.status === statusTypes.deleted)) {
      await handlePermanentlyDelete();
    } else {
      const filtered = selectedData.filter(item => item.status !== statusTypes.deleted);
      await handleMarkDelete(filtered);
    }
  }

  //To maintain consistent styling, we are using a hidden file input and having a normal styled button forward
  //click events to it
  const handleAddCsvClick = () => {
    if(fileRef.current !== null) {
      fileRef.current.click();
    }
  }

  //Since the actual file control is hidden we must display the chosen filename manually
  const handleAddCsvFileChange = async () => {
    if(fileRef.current !== null &&
       fileRef.current.files !== null &&
       fileRef.current.files.length > 0 &&
       mappingTypeId !== null) {
      const files = fileRef.current.files;
      const filename = files[0].name;
      const result = await prompt.prompt(`Do you want to upload ${filename}?`);
      if(result) {
        try {
          await mappingService.postCsvData(mappingTypeId, files[0]);
          if(statusFilter !== statusTypes.new) {
            setStatusFilter(statusTypes.new);
          }
          await fetchData();
        } catch {
          toast.error("Problem uploading CSV");
        }
      }
      fileRef.current.value = ""; //clear the input element's internal file list
    } else {
      toast.error("Problem uploading CSV");
    }
  }

  //Determines whether a given action button should be enabled, based on whether ALL selected rows can have that action
  //performed on them
  const shouldEnableButton = (button: ConditionalButton): boolean => {
    let show = false;
    if(button.conditions === undefined) { //undefined means truly unconditional, always show (use for Upload CSV)
      show = true;
    } else if(button.conditions === "any" && selectedData.length === 1) { //"any" means a single row of any status (use for Find Matches)
      show = true;
    } else if(Array.isArray(button.conditions) && selectedData.length > 0) {
      const conditions = button.conditions;
      show = selectedData.every(item => conditions.includes(item.status));
    }
    return show;
  }

  //Determines whether a given action button should be displayed at all, based on whether it is ever possible in the current
  //tab for that action to be allowed
  const shouldShowButton = (button: ConditionalButton): boolean => {
    let show = false;
    const selectedStatus = tabs[tabIndex].data;
    if(selectedStatus === statusTypes.allRecords || button.conditions === undefined || button.conditions === "any") {
      show = true;
    } else if(Array.isArray(button.conditions) && selectedStatus !== undefined && button.conditions.includes(selectedStatus)) {
      show = true;
    }
    return show;
  }

  //Determine whether to show the tabs and the button row
  const isValidData = (mappingTypeId !== null);

  //Determine whether the delete button is going to be on the form, which determines the special "Select a record (on left) to delete" caption
  const deleteButton = buttons.find(button => button.id === "delete");
  const deleteButtonPresent = deleteButton ? shouldShowButton(deleteButton) : false;

  //Conditionally render a given action button based on whether it should be shown and should be enabled
  const renderActionButton = (button: ConditionalButton): JSX.Element => {
    const show = shouldShowButton(button);
    const enable = shouldEnableButton(button);
    return <React.Fragment key={button.text}>
      {show &&
        <>
          {button.isUploadButton === true ?
            <>
              <Button isDarkButton={button.isDarkButton} disabled={!enable} onClick={handleAddCsvClick}>{button.text}</Button>
              <input ref={fileRef} type="file" style={{ display: "none" }} onChange={handleAddCsvFileChange} />
            </> :
            <Button isDarkButton={button.isDarkButton} disabled={!enable} onClick={button.action}>{button.text}</Button>
          }
        </>
      }
    </React.Fragment>
  }

  const [_, element] = bemify('locationmapping');
  return <div className={element('bottom-card')}>
    {openedRow &&
      <MatchedLocationsModal open={true}
                             row={openedRow}
                             update={fetchData}
                             onClose={() => setOpenedRow(undefined)} />
    }
    <div className={element('controls-row-top')}>
      <div className={element('controls-row-left-side')}>
        <div className={element('title-container')}>
          <h3 className={element('title-element')}><span className={element('title-text')}>Results Found</span><span className={element('title-pipe')}>|</span><span className={element('title-subtitle')}>{tab.label}</span></h3>
          <DebouncedTextbox text={searchText} onTextChange={setSearchText} legend='Filter' />
        </div>
      </div>
        <div className={element('controls-row-right-side')}>
          {isValidData &&
            <>
              <Tab tabs={tabs}
                   tabIndex={tabIndex}
                   onTabIndexChange={setTabIndex} />
            </>
          }
      </div>
    </div>
    <div className={element('grid-area')}>
      <DataGrid headers={gridHeaders}
                data={data}
                selectedRows={selectedData}
                onSelectedRowsChange={setSelectedData}
                emptyTableMessage='No results found. Please make your selections above.'
                enablePaging={true} />
    </div>
    <div className={element('controls-row-bottom')}>
      {isValidData &&
        <>
          {deleteButtonPresent &&
            <span>Select a record (on left) to delete.</span>
          }
          {buttons.map(button => 
            renderActionButton(button)
          )}
        </>
      }
    </div>
  </div>
}

type ButtonProps = {
  isDarkButton: boolean,
  onClick: () => void,
  disabled: boolean,
  children: React.ReactNode
}
/**
 * A button component that allows one of the custom styled button components to be used based on a prop
 */
const Button = ({ isDarkButton, disabled, onClick, children }: ButtonProps) => {
  const buttonProps = {
    disabled: disabled,
    onClick: onClick
  };
  return <>
    {isDarkButton ?
      <DarkButton {...buttonProps}>{children}</DarkButton> :
      <LightButton {...buttonProps}>{children}</LightButton>
    }
  </>
}

export default LocationMapping;

/**
 * Returns a value that determines whether a type matches a target.
 * @param {Any} source The source type to check.
 * @param {String} target The target type to compare against.
 * @returns Boolean
 */
export function isType(source, target) {
  return typeOf(source).toLowerCase() === target.toLowerCase();
}

/**
 * Returns the true data type of a type.
 * @param {Any} type The type to check.
 * @returns String
 */
export function typeOf(type) {
  // The native typeof operator gets us about 80% there
  // when it comes to type checking. The issue is that
  // it returns "object" even for nulls, which is not
  // accurate. To work around this, we can use the
  // built-in toString method on the base Object in
  // order to get the true type. toString returns the
  // type in the format of "[object object]". The
  // second type is the true type, so we'll slice
  // the first part to get the string representation.
  return Object.prototype.toString.call(type).slice(8, -1);
}

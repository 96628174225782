import React from 'react';
import bemify from '../../general/bemUtils';
import { CleanButton } from '../controls/cleanButton/cleanButton';

export type TabInfo<T = unknown> = {
  label: string,
  data?: T,
  content?: JSX.Element
}

type TabProps<T> = {
  tabs: (TabInfo<T> | string)[],
  tabIndex: number,
  onTabIndexChange: (tabIndex: number) => void,
  fullWidth?: boolean
}
/**
 * Represents a tab component.
 */
const Tab = <T extends unknown>(props: TabProps<T>) => {
  const {
    tabs: propTabs,
    tabIndex,
    onTabIndexChange,
    fullWidth: optionalFullWidth
  } = props;
  const fullWidth = optionalFullWidth === undefined ? false : optionalFullWidth;
  const tabs: TabInfo<T>[] = propTabs.map(tab => typeof tab === 'string' ? { label: tab } : tab);

  const [block, element] = bemify('tab');
  return (
    <div className={block()}>
      <ul className={element('tabs')} role="tablist">
        {tabs.map((tab, index) => {
          const className = element(
            'panel',
            [
              index === tabIndex ? 'active' : '',
              fullWidth ? 'full-width' : ''
            ]
          );
          return <li key={index} className={element('tab', fullWidth ? 'full-width' : '')}>
            <CleanButton className={className}
                         role="tab"
                         aria-selected={index === tabIndex}
                         id={`panel-${index}`}
                         aria-controls={`content-${index}`}
                         onClick={() => onTabIndexChange(index)}>
              <span>{tab.label}</span>
            </CleanButton>
          </li>
        })}
      </ul>
      {tabs.map((tab, index) =>
        <React.Fragment key={index}>
          {tab.content !== undefined &&
            <div className={element('content', index === tabIndex ? 'active' : '')}
                 id={`content-${index}`}
                 aria-labelledby={`panel-${index}`}>
              {tab.content}
            </div>
          }
        </React.Fragment>
      )}
    </div>
  );
};

export default Tab;

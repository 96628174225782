import React, { createContext, useContext, useState } from 'react';
import Rest from '../general/rest';
import { GridData } from '../models/gridData';
import { ExternalLocationStatus, ExternalLocationStatusId, MappingType } from '../models/lookups';
import { useOverlay } from '../hooks/useOverlay';
import { useSettings } from '../hooks/useSettings';

/**
 * The REST service for the Mapping Integration methods. Note that the loading overlay will be automatically applied
 * to every method in this service. To opt a method out of this behavior, add it to the optOutOfAutoOverlay array
 * declared below.
 */
class MappingService {
  private readonly baseUrl: string;
  constructor(apiBaseUrl: string) {
    this.baseUrl = apiBaseUrl;
  }

  public getExternalLocationStatuses = async (): Promise<ExternalLocationStatus[]> => {
    const url = `${this.baseUrl}/api/mapping/statuses`;
    const response = (await Rest.get<ExternalLocationStatus[]>(url));
    return response.data;
  }

  public getExternalLocations = async (guid: string, status: string | null, startDate: Date | null, endDate: Date | null): Promise<GridData[]> => {
    const headers = {'x-mapping-id': guid};
    let url = `${this.baseUrl}/api/mapping/locations?`;
    if (status) {
      url += `status=${status}&`;
    }
    if (startDate) {
      url += `uploadStartDate=${startDate.toISOString()}&`;
    }
    if (endDate) {
      url += `uploadEndDate=${endDate.toISOString()}&`;
    }
    // remove trailing &
    url = url.replace(/&$/, "");
    const response = (await Rest.get<GridData[]>(url, headers));
    return response.data;
  }

  public getMappedLocations = async (locationId: string): Promise<GridData[]> => {
    const response = (await Rest.get<GridData[]>(`${this.baseUrl}/api/mapping/mappedLocations?externalLocationId=${locationId}`));
    return response.data;
  }

  public deleteMappingLocation = async (guid: string, locationId: string): Promise<any[]> => {
      const headers = {'x-mapping-id': guid};
      const url = `${this.baseUrl}/api/mapping/location?externalLocationId=${locationId}`;
      const response = (await Rest.delete<any>(url, headers));
      return response.data;
  }

  public getMappingTypes = async (): Promise<MappingType[]> => {
    const response = await Rest.get<MappingType[]>(`${this.baseUrl}/api/mapping/mappingTypes`);
    return response.data;
  }

  public updateMappingStatus = async (locationId: string, status: ExternalLocationStatusId, locKey?: string | null | undefined): Promise<void> => {
    const data = 
    { 
      ExternalLocationId: locationId, 
      ExternalLocationStatusId: status,
      LocKey: locKey 
    };
    await Rest.put(`${this.baseUrl}/api/mapping/status`, data);
  }

  public postCsvData = async (mappingTypeId: string, file: File): Promise<void> => {
    const headers = {'x-mapping-id': mappingTypeId};
    const formData = new FormData();
    formData.append("file", file);
    await Rest.post(`${this.baseUrl}/api/mapping/csvImport`, formData, headers);
  }

  public getCsvData = async (mappingTypeId: string, externalLocationIds: string[]): Promise<Blob> => {
    const headers = {'x-mapping-id': mappingTypeId};
    const response = await Rest.get<string>(`${this.baseUrl}/api/mapping/csvExport?ids=${externalLocationIds.join(',')}`, headers);
    const text = response.data;
    const blob = new Blob([text], { type: 'text/plain' });
    return blob;
  }
}

//Put the names of any methods that should NOT have a loading spinner applied here.
const optOutOfAutoOverlay: (keyof MappingService)[] = [];



/*** Below: code for wiring up the overlay to the mapping service and placing it into the React hierarchy. Should not
            need to be touched if just adding new API calls to the service ***/

//This esoteric-looking bit of code just adds the loading overlay handlers to every method in the service
const createService = (baseUrl: string, before: () => void, after: () => void) => {
  const service = new MappingService(baseUrl);
  for(const propName of Object.getOwnPropertyNames(service)) {
    if(typeof service[propName] === 'function' && !optOutOfAutoOverlay.includes(propName as keyof MappingService)) {
      const oldFunc = service[propName];
      service[propName] = async (...args: any[]) => {
        before();
        let result = undefined;
        try {
          result = await Promise.resolve(oldFunc(...args));
        } finally {
          after();
        }
        return result;
      }
    }
  }
  return service;
}

//Create a context for the mapping service. The undefined! saves an unneeded instantiation since WithMappingService
//is going to create the overlay-doctored-up service immediately. Exporting this is probably a bad idea.
const MappingServiceContext = createContext<MappingService>(undefined!);

type WithMappingServiceProps = {
  children: React.ReactNode
}
/**
 * Component for adding the loading overlay to the mapping service and embedding the service into the React
 * hierarchy. This component MUST be a descendant of <WithOverlay> (see Withs.tsx for usage)
 */
export const WithMappingService = (props: WithMappingServiceProps) => {
  const settings = useSettings();
  const overlay = useOverlay();
  const [service] = useState<MappingService>(createService(settings.REACT_APP_API_BASE_URL, overlay.show, overlay.hide));
  return <MappingServiceContext.Provider value={service}>
    {props.children}
  </MappingServiceContext.Provider>
}

/**
 * Hook for getting the mapping service
 * @returns The mapping service
 */
const useMappingService = (): MappingService => {
  const mappingService = useContext(MappingServiceContext);
  return mappingService;
}
export default useMappingService;

import React, { useCallback, useEffect, useRef, useState } from "react"
import bemify from "../../../general/bemUtils"
import { CleanButton } from "../cleanButton/cleanButton";

//Returns true if the supplied text either can be parsed as number or is an empty string
const isNumberOrEmpty = (text: string): boolean => {
  const asNum = text === "" ? 0 : Number.parseInt(text);
  return !Number.isNaN(asNum);
}

//Pads a string with zeros
const pad = (origStr: string, digits: number = 2): string => {
  let str = `${origStr}`;
  while(str.length < digits) {
    str = `0${str}`;
  }
  return str;
}

//Takes an input that's either a number or a string and returns it as a number
const toNum = (possibleNum: number | string): number => {
  return typeof possibleNum === 'string' ? Number.parseInt(possibleNum) : possibleNum;
}

//Gets the year, month, and day of a date as numbers, using 0s for a null date
const getNumParts = (date: Date | null): { month: number, day: number, year: number } => {
  const year: number = date ? date.getFullYear() : 0;
  const month: number = date ? date.getMonth() + 1 : 0;
  const day: number = date ? date.getDate() : 0;
  return { month, day, year };
}

//Gets the year, month, and day of a date as strings, using 0s for a null date, and using the correct
//number of digits for each
const getStrParts = (date: Date | null): { month: string, day: string, year: string } => {
  const { year: yearNum, month: monthNum, day: dayNum } = getNumParts(date);
  const year: string = pad(`${date ? yearNum : '0'}`, 4);
  const month: string = pad(`${date ? monthNum: '0'}`);
  const day: string = pad(`${date ? dayNum : '0'}`);
  return { month, day, year };
}

//Converts a date into a string that is a valid value of an <input type="date" />
const dateToDateInputString = (date: Date): string => {
  const { year, month, day } = getStrParts(date);
  return `${year}-${month}-${day}`;
}

//verifies that the date is not a broken "Invalid Date"
const isDateValid = (date: Date): boolean => {
  return !Number.isNaN(date.getTime());
}

//checks if the year/month/day represent a real date by seeing what JS does to these values when turned into a date. If
//it transforms them, e.g. by rolling a month 13 into January of the next year, then we will call it an invalid date
//"month" param should be standard month, not zero-based month
const areDatePartsValid = (year: string | number, month: string | number, day: string | number): boolean => {
  const rawYearNum = toNum(year);
  const yearNum = rawYearNum >= 0 && rawYearNum <= 99 ? rawYearNum + 1900 : rawYearNum;
  const monthNum = toNum(month) - 1;
  const dayNum = toNum(day);
  const asDate = new Date(yearNum, monthNum, dayNum);
  const valid = isDateValid(asDate) && yearNum === asDate.getFullYear() && monthNum === asDate.getMonth() && dayNum === asDate.getDate();
  return valid;
}

//Checks if two "date or null" values are equal
const datesEqual = (date1: Date | null, date2: Date | null) => {
  let equal = false;
  if(date1 === null && date2 === null) {
    equal = true;
  } else if(date1 !== null && date2 !== null && date1.getTime() === date2.getTime()) {
    equal = true;
  }
  return equal;
}

//Determines if an element has input focus
const isFocused = (element: HTMLElement | null): boolean => {
  return document.activeElement !== null && document.activeElement === element;
}

//"month" param should be standard month, not zero-based month
const createDate = (year: number | string, month: number | string, day: number | string): Date | null => {
  const yearNum = toNum(year);
  const monthNum = toNum(month) - 1;
  const dayNum = toNum(day);
  let newDate: Date | null = null;
  if(!Number.isNaN(yearNum) && !Number.isNaN(monthNum) && !Number.isNaN(dayNum) && areDatePartsValid(yearNum, monthNum + 1, dayNum)) {
    newDate = new Date(yearNum, monthNum, dayNum);
  }
  return newDate;
}

type DateFieldProps = {
  value: Date | null,
  onChange: (value: Date | null) => void,
  legend?: string,
  required?: boolean
}
/**
 * Represents a date input component that has a consistent look and feel across browsers.
 * todo: the actual date picker popout still uses the native popout, this needs to be built
 */
const DateField = ({
  value,
  onChange,
  legend,
  required
}: DateFieldProps) => {
  const [block, element] = bemify("datefield");

  //The text that will live in the year, month, and day text editors
  const [yearText, setYearText] = useState("0000");
  const [monthText, setMonthText] = useState("00");
  const [dayText, setDayText] = useState("00");

  //Indicates if the date value has ever been touched by the user. Used so that we do not show the red
  //error border for null dates unless the user has first tried to set a date
  const [userChangedOnce, setUserChangedOnce] = useState(false);

  //Refs for all the HTML inputs
  const dateInputRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);

  //Handler for the Date Input change. This will be fired when the user opens the pop-up date input and selects
  //a value. Otherwise, this input is hidden.
  const handleDateInputChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
    let newDate: Date | null = null;
    const dateText = evt.target.value;
    const parts = dateText.split("-");
    if(parts.length === 3) {
      newDate = createDate(parts[0], parts[1], parts[2]);
    }
    onChange(newDate);
  }

  //Handler for when the visible text fields change
  const handleDateTextFieldChange = (year: string, month: string, day: string): void => {
    const newDate = createDate(year, month, day);
    if(!datesEqual(newDate, value)) {
      onChange(newDate);
    }
  }

  //Takes the date value prop and sets the text of the text fields accordingly. This method does it unconditionally,
  //so conditions (such as ensuring user focus is not in the editors) should be applied by the caller.
  const refreshEditorsFromDateValue = useCallback(() => {
    const { year, month, day } = getStrParts(value);
    if(year !== yearText) {
      setYearText(year);
    }
    if(month !== monthText) {
      setMonthText(month);
    }
    if(day !== dayText) {
      setDayText(day);
    }
  }, [dayText, monthText, yearText, value]);

  //Effect to refresh the text field values when the date changes and the user is not currently focused in an editor
  const isEditorFocused = isFocused(yearRef.current) || isFocused(monthRef.current) || isFocused(dayRef.current);
  useEffect(() => {
    if(!isEditorFocused) {
      refreshEditorsFromDateValue();
    }
  }, [isEditorFocused, value, refreshEditorsFromDateValue]);

  //Individual handlers for the text editors
  const handleMonthChange = (text: string): void => {
    if(isNumberOrEmpty(text)) {
      handleDateTextFieldChange(yearText, text, dayText);
      setMonthText(text);
    }
  }
  const handleDayChange = (text: string): void => {
    if(isNumberOrEmpty(text)) {
      handleDateTextFieldChange(yearText, monthText, text);
      setDayText(text);
    }
  }
  const handleYearChange = (text: string): void => {
    if(isNumberOrEmpty(text)) {
      handleDateTextFieldChange(text, monthText, dayText);
      setYearText(text);
    }
  }

  //Did we tab/click over to another date field, or is focus going to something else entirely?
  const isStayingInEditor = (evt: React.FocusEvent<HTMLInputElement>): boolean => {
    let staying = false;
    const related = evt.relatedTarget;
    if(related !== null && (related === monthRef.current || related === dayRef.current || related === yearRef.current)) {
      staying = true;
    }
    return staying;
  }

  //Handle loss of focus of the editor fields, so now we can do cosmetic changes like add leading zeroes
  const handleEditorBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    if(!isStayingInEditor(evt)) {
      setUserChangedOnce(true);
      if(value !== null) {
        refreshEditorsFromDateValue();
      }
    }
  }

  //Select all text on focus of an editor field
  const handleEditorFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    evt.target.select();
  }

  //String to place into the hidden date input
  const dateInputValueStr: string = (value === undefined || value === null) ? '' : dateToDateInputString(value);

  //Determine whether to show the red error outline
  const isInvalid = required === true && (userChangedOnce && (value === null));

  return <span className={block(isInvalid ? 'invalid' : undefined)}>
    {legend &&
      <span className={element('legend')}>{legend}</span>
    }
    <span className={element('fields')}>
      <input type="text" ref={monthRef} className={element('monthpart')} value={monthText} onChange={evt => handleMonthChange(evt.target.value)} onBlur={handleEditorBlur} onFocus={handleEditorFocus} />
      <span className={element('slash')}>/</span>
      <input type="text" ref={dayRef} className={element('daypart')} value={dayText} onChange={evt => handleDayChange(evt.target.value)} onBlur={handleEditorBlur} onFocus={handleEditorFocus} />
      <span className={element('slash')}>/</span>
      <input type="text" ref={yearRef} className={element('yearpart')} value={yearText} onChange={evt => handleYearChange(evt.target.value)} onBlur={handleEditorBlur} onFocus={handleEditorFocus} />
    </span>
    <CleanButton onClick={() => dateInputRef.current?.showPicker()} className={element('button')}>
      <img src="/assets/icons/calendar.svg" />
    </CleanButton>
    <input type="date" ref={dateInputRef} value={dateInputValueStr} onChange={handleDateInputChange} className={element('input')} />
  </span>
}

export default DateField;

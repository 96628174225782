export type MappingType = {
  guid: string,
  description: string
}

export type ExternalLocationStatusId = -1 |
                                       1 |
                                       2 |
                                       3 |
                                       4 |
                                       5

export type ExternalLocationStatus = {
  externalLocationStatusId: ExternalLocationStatusId,
  statusValue: string
}

/**
 * Since the frontend needs to know about the specific status IDs in order to know what
 * options to display, list them here in a type safe manner.
 */
type ExternalLocationStatusConstants = {
  readonly allRecords: ExternalLocationStatusId,
  readonly new: ExternalLocationStatusId,
  readonly unmapped: ExternalLocationStatusId,
  readonly mappedActive: ExternalLocationStatusId,
  readonly mappedInactive: ExternalLocationStatusId,
  readonly deleted: ExternalLocationStatusId
}
export const statusTypes: ExternalLocationStatusConstants = {
  allRecords: -1,
  new: 1,
  unmapped: 2,
  mappedActive: 3,
  mappedInactive: 4,
  deleted: 5
}


import React from 'react';
import LocationMapping from '../../components/locationmapping/locationmapping';
import Authwall from '../../components/authwall/authwall';
import bemify from '../../general/bemUtils';

/**
 * Represents the home page of the app.
 *
 * Inside the authwall needs to be a separate component (HomeInner) because the authwall creates a context
 * that is then consumed by useContext.
*/
const Home = () => {
  return <Authwall>
    <HomeInner />
  </Authwall>
};

/**
 * This component is responsible for rendering the logo and title area, under which it places a
 * LocationMapping component which contains the meat of the app.
 */
const HomeInner = () => {
  const [block, element] = bemify('homepage');
  return <div className={block()}>
    <div className={element("centered")}>
      <div className={element("title-area")}>
        <div className={element("title-and-logo")}>
          <h2 className={element("title")}>4PC Location Mapping</h2>
        </div>
        <div>
          {/* spacer */}
        </div>
      </div>
      <LocationMapping />
    </div>
  </div>
}

export default Home;

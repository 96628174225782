import { useCallback, useContext, useState } from "react";
import { ToastContext, toastTypes } from "../components/withToasts/WithToasts";

export type ErrorFunc = (message: string) => void;
export type WarnFunc = (message: string) => void;
export type Toasts = {
  readonly error: ErrorFunc,
  readonly warn: WarnFunc
}

/**
 * Hook for accessing the toast methods
 * @returns Toast methods
 */
export const useToast = (): Toasts => {
  const openToast = useContext(ToastContext);
  const error: ErrorFunc = useCallback((message: string) => {
    openToast(message, toastTypes.error);
  }, [openToast]);
  const warn: WarnFunc = useCallback((message: string) => {
    openToast(message, toastTypes.warning);
  }, [openToast]);
  const [toasts] = useState<Toasts>({
    error: error,
    warn: warn
  });
  return toasts;
}

//const baseUrl = "http://localhost:7018/api"
const baseUrl = "/api"

export type EnvSettings = {
  REACT_APP_API_BASE_URL: string,
  REACT_APP_B2CAAD_AUTHORITY: string,
  REACT_APP_B2CAAD_TENANT: string,
  REACT_APP_B2CAAD_CLIENT_ID: string,
  REACT_APP_B2CAAD_TENANT_ID: string,
  REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW: string,
  REACT_APP_B2CAAD_LOCAL_SIGNIN2_USERFLOW: string,
  REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW: string,
  REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW: string,
  REACT_APP_B2CAAD_REDIRECT_URI: string
}

const createLocalSettings = (): EnvSettings | undefined => {
  let settings: EnvSettings | undefined;
  try {
    settings = {
      REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL ?? '',
      REACT_APP_B2CAAD_AUTHORITY: process.env.REACT_APP_B2CAAD_AUTHORITY ?? '',
      REACT_APP_B2CAAD_TENANT: process.env.REACT_APP_B2CAAD_TENANT ?? '',
      REACT_APP_B2CAAD_CLIENT_ID: process.env.REACT_APP_B2CAAD_CLIENT_ID ?? '',
      REACT_APP_B2CAAD_TENANT_ID: process.env.REACT_APP_B2CAAD_TENANT_ID ?? '',
      REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW: process.env.REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW ?? '',
      REACT_APP_B2CAAD_LOCAL_SIGNIN2_USERFLOW: process.env.REACT_APP_B2CAAD_LOCAL_SIGNIN2_USERFLOW ?? '',
      REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW: process.env.REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW ?? '',
      REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW: process.env.REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW ?? '',
      REACT_APP_B2CAAD_REDIRECT_URI: process.env.REACT_APP_B2CAAD_REDIRECT_URI ?? ''
    }
  } catch {
    settings = undefined;
  }
  return settings;
};

export class SettingsService {
  public static getSettings = async (): Promise<EnvSettings | undefined> => {
    let settings: EnvSettings | undefined = undefined;
    try {
      const res = await fetch(`${baseUrl}/DynamicSettings`);
      const json = await res.json();
      settings = 'REACT_APP_API_BASE_URL' in json.settings ? json.settings as EnvSettings : createLocalSettings();
    } catch {
      settings = createLocalSettings();
    }
    return settings;
  }
}

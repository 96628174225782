/**
 * Represents http status codes.
 */
export class HttpStatusCode {
  /**
   * Indicates an internal server error http status.
   */
  static internalServerError = 500;

  /**
   * Indicates a not found http status.
   */
  static notFound = 404;

  /**
   * Indicates an ok http status.
   */
  static ok = 200;

  /**
   * Indicates a request timeout http status.
   */
  static requestTimeout = 408;

  /**
   * Indicates an unauthorized http status.
   */
  static unauthorized = 401;
}

/**
 * Represents the supported types in JavaScript.
 */
export class Types {
  /**
   * Indicates an array type.
   */
  static array = 'Array';

  /**
   * Indicates a boolean type
   */
  static boolean = 'Boolean';

  /**
   * Indicates a date type.
   */
  static date = 'Date';

  /**
   * Indicates a infinity number type.
   */
  static infinity = 'Infinity';

  /**
   * Indicates a function type.
   */
  static function = 'Function';

  /**
   * Indicates an object type.
   */
  static object = 'Object';

  /**
   * Indicates a null type.
   */
  static null = 'Null';

  /**
   * Indicates a not a number type.
   */
  static nan = 'NaN';

  /**
   * Indicates a number type.
   */
  static number = 'Number';

  /**
   * Indicates a string type.
   */
  static string = 'String';

  /**
   * Indicates an undefined type.
   */
  static undefined = 'Undefined';
}

// Lock object to prevent modification (true static).
Object.freeze(HttpStatusCode);
Object.freeze(Types);

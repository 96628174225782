import { useContext, useEffect, useRef, useState } from "react";
import { PromptContext, PromptMode } from "../components/withPromptModal/WithPromptModal";

//Different types of prompts
export type AlertFunc = (message: string | JSX.Element, title?: string) => Promise<void>;
export type PromptFunc = (message: string | JSX.Element, title?: string) => Promise<boolean>;
export type PromptWithCancelFunc = (message: string | JSX.Element, title?: string) => Promise<boolean | undefined>;
export type ModalFunc = (content: JSX.Element) => Promise<void>;
export type CloseModalFunc = () => void;
export type Prompts = {
  readonly alert: AlertFunc,
  readonly prompt: PromptFunc,
  readonly promptWithCancel: PromptWithCancelFunc,
  readonly customModal: ModalFunc,
  readonly closeCustomModal: CloseModalFunc
}

/**
 * Hook for showing basic prompts and getting the button the user clicked in a simple awaitable fashion.
 * @returns Methods for using the prompt modal
 */
export const usePrompt = (): Prompts => {
  const setModalProps = useContext(PromptContext);
  const [modalResolver, setModalResolver] = useState<{ resolve: ((value: void | PromiseLike<void>) => void)} | undefined>(undefined);
  const modalResolverRef = useRef<{ resolve: ((value: void | PromiseLike<void>) => void)} | undefined>(modalResolver);
  useEffect(() => {
    modalResolverRef.current = modalResolver;
  });

  const alert: AlertFunc = (message: string | JSX.Element, title?: string): Promise<void> => {
    return new Promise<void>(resolve => {
      const callback: (() => void) = () => {
        setModalProps({ open: false, onResponse: ()=>{}});
        resolve();
      };
      setModalProps({
        open: true,
        prompt: message,
        title: title,
        mode: PromptMode.Ok,
        onResponse: callback
      });
    });
  };
  const prompt: PromptFunc = (message: string | JSX.Element, title?: string): Promise<boolean> => {
    return new Promise<boolean>(resolve => {
      const callback: ((yes: boolean | undefined) => void) = (yes) => {
        setModalProps({ open: false, onResponse: ()=>{}});
        resolve(yes ?? true);
      };
      setModalProps({
        open: true,
        prompt: message,
        title: title,
        mode: PromptMode.YesNo,
        onResponse: callback
      });
    });
  };
  const promptWithCancel: PromptWithCancelFunc = (message: string | JSX.Element, title?: string): Promise<boolean | undefined> => {
    return new Promise<boolean | undefined>(resolve => {
      const callback: ((yes: boolean | undefined) => void) = (yes) => {
        setModalProps({ open: false, onResponse: ()=>{}});
        resolve(yes);
      };
      setModalProps({
        open: true,
        prompt: message,
        title: title,
        mode: PromptMode.YesNoCancel,
        onResponse: callback
      });
    });
  };
  const customModal: ModalFunc = (content: JSX.Element): Promise<void> => {
    return new Promise<void>(resolve => {
      setModalResolver({ resolve: resolve });
      setModalProps({
        open: true,
        onResponse: ()=>{},
        content: content
      });
    });
  }
  const closeCustomModal: CloseModalFunc = (): void => {
    if(modalResolverRef.current !== undefined) {
      setModalProps({ open: false, onResponse: ()=>{}, content: undefined});
      modalResolverRef.current.resolve();
    }
  }

  const prompts: Prompts = {
    alert: alert,
    prompt: prompt,
    promptWithCancel: promptWithCancel,
    customModal: customModal,
    closeCustomModal: closeCustomModal
  }
  return prompts;
}

import React from 'react';
import { WithToasts } from '../withToasts/WithToasts';
import { WithPromptModal } from '../withPromptModal/WithPromptModal';
import { WithOverlay } from '../withOverlay/WithOverlay';
import { WithMappingService } from '../../services/mappingService';
import { WithSettings } from '../withSettings/WithSettings';

type WithsProps = {
  children: React.ReactNode
}

/**
 * This component bundles together every (non-library) component whose sole purpose is to render some sort of service
 * to all its descendants.
 */
export const Withs = (props: WithsProps) => {
  return <WithOverlay>
    <WithSettings>
      <WithToasts>
        <WithPromptModal>
          <WithMappingService>
           {props.children}
          </WithMappingService>
        </WithPromptModal>
      </WithToasts>
    </WithSettings>
  </WithOverlay>
}


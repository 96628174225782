import React, { CSSProperties, createContext, useCallback, useEffect, useRef, useState } from 'react';

//Declare the toast types here and define their appearance in the CSS file
type ToastClassName = "newtoast__errortype" | "newtoast__warningtype";
type ToastTypes = {
  readonly error: ToastClassName,
  readonly warning: ToastClassName
}
export const toastTypes: ToastTypes = {
  error: "newtoast__errortype",
  warning: "newtoast__warningtype"
};


type ToastInfo = {
  key: number, //keying off index produces weird behavior in this case, use totally unique key
  type: ToastClassName,
  text: string
}

type ToastsProps = {
  toasts: ToastInfo[],
  onToastClose: (toast: ToastInfo) => void
}
/**
 * This component represents a column of toasts (self-disappearing messages) that will be shown in the center of the screen
 */
const Toasts = ({ toasts, onToastClose }: ToastsProps) => {
  const standardTimeout = 3000;
  return <div className='newtoast'>
    {toasts.map((toast) =>
      <Toast key={toast.key} typeClass={toast.type} timeout={standardTimeout} onClose={() => onToastClose(toast)} >{toast.text}</Toast>
    )}
  </div>
}

type ToastProps = {
  children?: React.ReactNode,
  onClose: () => void,
  typeClass: ToastClassName,
  timeout: number
}
/**
 * This component represents an individual self-disappearing message with the following features:
 * 
 * 1. Fade-in and fade-out
 * 2. Click-to-dismiss
 * 3. Hover to make permanent (must click to dismiss). Configurable with the configHover const. Doesn't
 *    seem to work in <StrictMode> (should work fine in prod, comment out <StrictMode> in index.jsx to test)
 */
const Toast = ({ children, onClose, typeClass, timeout }: ToastProps) => {
  const configHover = true;

  const fadeMs = 750;

  //Whether to start the initial fade-in, about 50ms after mounting, to prevent race conditions
  const [fadeinStarted, setFadeinStarted] = useState<boolean>(false);

  //Whether a condition has been met to start fading out (timer expiration or user click)
  const [closing, setClosing] = useState<boolean>(false);

  //Whether permanent mode (no auto-hide) is on, which is set when the mouse hovers over the toast
  const [perma, setPerma] = useState<boolean>(false);

  //The window timeout for the main lifetime of the toast (when it has not started fading out)
  const [displayTimeout, setDisplayTimeout] = useState<number | undefined>(undefined);

  //The window timeout for the fade-out process of the toast. The toast is closed and removed from the
  //parent once this expires.
  const [closeTimeout, setCloseTimeout] = useState<number | undefined>(undefined);

  //CSS class to display based on whether the toast is fully open or whether fade-out has begun
  const displayClass = (closing || !fadeinStarted) ? "newtoast__closing" : "newtoast__open";

  //Set the final timer to fully close the toast after fade-out has started
  useEffect(() => {
    if(closing && closeTimeout === undefined) {
      const newCloseTimeout = window.setTimeout(() => {
        onClose();
      }, fadeMs);
      setCloseTimeout(newCloseTimeout);
    } else if(closeTimeout !== undefined && !closing) {
      window.clearTimeout(closeTimeout);
      setCloseTimeout(undefined);
    }
  }, [closing, perma, closeTimeout, onClose]);

  //Set the initial timer for how long we want to hold the toast open before starting the fade-out
  useEffect(() => {
    if(displayTimeout === undefined && !perma) {
      const newDisplayTimeout = window.setTimeout(() => {
        setClosing(true);
      }, timeout);
      setDisplayTimeout(newDisplayTimeout);
    } else if(displayTimeout !== undefined && perma) {
      window.clearTimeout(displayTimeout);
      setDisplayTimeout(undefined);
    }
  }, [timeout, perma, displayTimeout, onClose]);

  //Introduce a tiny delay before fade-in to ensure the component is in the DOM. Without this, race conditions
  //were making it unpredictable whether the animation would occur or the toast would start fully formed
  useEffect(() => {
    if(!fadeinStarted) {
      window.setTimeout(() => {
        setFadeinStarted(true);
      }, 50);
    }
  }, [fadeinStarted]);

  //Set the toast to permanent mode on mouse hover
  const handleHover = () => {
    if(!perma) {
      setClosing(false);
      setPerma(true);
    }
  }

  //If they hovered during fade, snap to full
  const doFade = !(perma && !closing);

  //Use inline style to apply fade duration and add border in permanent mode
  const style: CSSProperties = {
    transitionDuration: `${doFade ? fadeMs : 0}ms`,
    border: (perma && !closing) ? "1px solid black" : undefined
  };

  return <div className={`newtoast__toast ${typeClass} ${displayClass}`}
              style={style}
              onClick={() => setClosing(true)}
              onMouseMove={configHover ? handleHover : undefined}>
    <span>{children}</span>
  </div>
}

//Context for giving descendants an interface by which they can fire off toasts
export const ToastContext = createContext<(message: string, type: ToastClassName) => void>(() => {});

type WithToastsProps = {
  children: React.ReactNode
}
/**
 * Component to put the toasts in the DOM and provide the interface to it to descendants via a context
 */
export const WithToasts = (props: WithToastsProps) => {
  const [toasts, setToasts] = useState<ToastInfo[]>([]);
  const toastKey = useRef<number>(0);

  const openToast = useCallback((message: string, type: ToastClassName) => {
    setToasts(prev => {
      return [...prev, {
      key: toastKey.current++,
      type: type,
      text: message
    }]});
  }, []);
  const handleToastClose = (toast: ToastInfo) => {
    setToasts(prev => {
      return prev.filter(item => item.key !== toast.key)
    });
  };
  return <ToastContext.Provider value={openToast}>
    <Toasts toasts={toasts} onToastClose={handleToastClose} />
    {props.children}
  </ToastContext.Provider>
}


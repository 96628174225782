import React from 'react';
import { createRoot } from 'react-dom/client';
import './init';
import App from './app';

const main = document.getElementById('main');
const root = createRoot(main);
root.render(
  // <StrictMode> //this is causing duplicate API calls even under a production build so out it goes until we can get it to stop doing that
    <App />
  // </StrictMode>
);

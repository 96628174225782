import React, { ReactNode } from 'react';
import Utils from '../../../general/utils';

/**
 * A button using a common styling for buttons across this app
 */
type ButtonProps = {
  children?: ReactNode
  className?: string
  onClick?: () => void,
  disabled?: boolean
}
const Button = (props: ButtonProps) => {
  return <button {...props}>
    {props.children}
  </button>
}

export const LightButton = (props: ButtonProps) => {
  return <Button {...props} className={Utils.css("buttoncontrol__light", props.className)}>
    {props.children}
  </Button>
}

export const DarkButton = (props: ButtonProps) => {
  return <Button {...props} className={Utils.css("buttoncontrol__dark", props.className)}>
    {props.children}
  </Button>
}


import React, { useEffect, useState } from 'react';
import { GridData } from '../../models/gridData';
import DataGrid, { ColumnHeader } from '../dataGrid/dataGrid';
import { CleanButton } from '../controls/cleanButton/cleanButton';
import { statusTypes } from '../../models/lookups';
import { useToast } from '../../hooks/useToast';
import useMappingService from '../../services/mappingService';
import { LightButton } from '../controls/button/button';
import bemify from '../../general/bemUtils';

type MatchedLocationsModalProps = {
  open: boolean,
  row: GridData,
  update: () => Promise<void>,
  onClose: () => void
}

/**
 * Modal for displaying matches of a given location, and performing updates on those matches. Responsible for
 * rendering the grids and handling user row selection and actions.
 */
export const MatchedLocationsModal = (props: MatchedLocationsModalProps) => {

  //The possible locations to map the supplied row to
  const [mapValues, setMapValues] = useState<GridData[]>([]);

  //The currently selected location for mapping the supplied row
  const [selectedRow, setSelectedRow] = useState<GridData | undefined>(undefined);


  //Get services from ancestor contexts. These services are designed to be unchanging so it is safe to put them
  //in dependency arrays to prevent React warnings.

  //Toasts
  const toast = useToast();

  //The API service
  const mappingService = useMappingService();


  //Fetch the map options for the grid
  const externalId = props.row.externalId;
  const locKey = props.row.locKey;
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        props.row.locationStatus = null;
        props.row.coverStatus = null;

        const fetchedData = await mappingService.getMappedLocations(externalId);
        if(fetchedData.length > 0) {
          for(const item of fetchedData) {
            item.name = (item as any).orgName;
            item.externalId = item.locKey ?? "";
          }
          setMapValues(fetchedData);
          if(locKey) {
            const mappedRow = fetchedData.find(item => item.locKey === locKey);
            if(mappedRow !== undefined) {
              setSelectedRow(mappedRow);
            }
          }
        } else {
          toast.error("No mapped locations were found")
        }
      } catch {
        toast.error("Failed to get mapped locations")
      }
    };
    fetchData();
  }, [externalId, toast, mappingService, locKey]);

  //Lay out the grid
  const gridHeaders: ColumnHeader<GridData>[] = [{
    field: "externalId",
    headerName: "External ID"
  }, {
    field: "name",
    headerName: "Location Name"
  }, {
    field: "address1", //todo: address2? design gives example "5467 North Bend Avenue, Ste 100" for this field
    headerName: "Location Address"
  }, {
    field: "city",
    headerName: "City"
  }, {
    field: "state",
    headerName: "State"
  }, {
    field: "zip",
    headerName: "Zip"
  }, {
    field: "locationStatus",
    headerName: "Loc Status"
  }, {
    field: "coverStatus",
    headerName: "Cover Status"
  }];

  //Ensure that the first row shows the headers, as per the design. Could possibly achieve this design's look using
  //normal table headers (which we are currently hiding) and good CSS, but this seemed a simpler way to achieve the look
  const standardCustomContent = (row: GridData, rowIndex: number, column: ColumnHeader<GridData>) => {
    return rowIndex > 0 ? undefined : <div className={element('first-row-cell')}>
      <div className={element('first-row-cell-header')}>{column.headerName}</div>
      <div className={element('first-row-cell-content')}>{row[column.field]}</div>
    </div>
  }
  for(const header of gridHeaders) {
    header.customContent = standardCustomContent;
  }

  //Handler for remove matches button
  const handleRemoveMatches = async () => {
    await mappingService.updateMappingStatus(props.row.externalId, statusTypes.unmapped)
    await props.update();
    setSelectedRow(undefined);
  }

  //Handler for map matches button
  const handleMapMatchesClick = async () => {
    await mappingService.updateMappingStatus(props.row.externalId, statusTypes.mappedActive, selectedRow?.locKey)
    await props.update();
  }

  //Prepend top row and spacer rows to grid data (see _modaldatagrid.scss for spacer row handling)
  const rowsToShow: (GridData | undefined)[] = [props.row, undefined, undefined, ...mapValues];

  //Determine the enabled status of the remove and match buttons
  const enableRemove = selectedRow !== undefined && selectedRow.locKey === locKey;
  const enableMap = selectedRow !== undefined && selectedRow.locKey !== locKey;

  const [block, element] = bemify("matchedlocationsmodal");
  return <>
    {props.open &&
      <div className={block()}>
        <div className={element('panel')}>
          <div className={element('title-row')}>
            <div className={element('title-row-side')}>
              <div className={element('title-container')}>
                <h3 className={element('title-text')}>Map Matching Location</h3>
              </div>
            </div>
            <div className={element('title-row-side')}>
              <div className={element('placekey-area')}>
                <span className={element('placekey-name')}>PlaceKey</span>
                <span className={element('placekey-value')}>{props.row.placeKey}</span>
              </div>
              <CleanButton className={element('close-button')} onClick={props.onClose}><img src="/assets/icons/close-button.svg" /></CleanButton>
            </div>
          </div>
          <div className={element('body')}>
            <div className={element('bottom-grid-area')}>
              <DataGrid headers={gridHeaders}
                        data={rowsToShow}
                        selectedRows={[selectedRow]}
                        onSelectedRowsChange={(rows) => setSelectedRow(rows[0])}
                        selectionMode='single'
                        selectionColumnPosition='last'
                        pagingStartIndex={3}
                        bemifyParam='modaldatagrid'
                        showHeaders={false} />
            </div>
            <div className={element('button-area')}>
              <span>Select a record to match.</span>
              <LightButton onClick={handleRemoveMatches} disabled={!enableRemove}>Remove Match</LightButton>
              <LightButton onClick={handleMapMatchesClick} disabled={!enableMap}>Map Location</LightButton>
            </div>
          </div>
        </div>
      </div>
    }
  </>
}


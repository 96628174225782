import React, { useState, MouseEvent } from 'react';
import bemify from '../../../general/bemUtils';
import Utils from '../../../general/utils';
import ClickAwayListener from '../../clickAwayListener/clickAwayListener';

type NullDropdownValue = "<null>"

type DropdownProps<T> = {
  items: T[],
  value?: T | null,
  onChange?: (item: T | null) => void,
  toStringFunc?: (item: T) => string,
  nullOptionName?: string, //if omitted, will omit null option
  placeholder?: string,
  legend?: string
  caret?: React.ReactNode,
  className?: string
}
/**
 * Represents a dropdown component that has a consistent look and feel across browsers.
 */
const Dropdown = <T extends unknown>({
  items,
  value,
  onChange,
  toStringFunc: optionalToStringFunc,
  nullOptionName,
  placeholder,
  legend,
  caret,
  className
}: DropdownProps<T>) => {
  const [block, element] = bemify('dropdown');
  const [open, setOpen] = useState(false);
  const nullValue: NullDropdownValue = "<null>";

  const toStringFunc = (item: T | null) => {
    let str: string;
    if(item === null && nullOptionName) {
      str = nullOptionName;
    } else if(item !== null && optionalToStringFunc !== undefined) {
      str = optionalToStringFunc(item);
    } else {
      str = `${item}`;
    }
    return str;
  }

  const handleChange = (newValue: T | null): void => {
    if(onChange !== undefined && newValue !== value) {
      setOpen(false);
      onChange(newValue);
    }
  }

  let elementValue: number | undefined | NullDropdownValue = undefined;
  let valueText: string = "";
  if(value !== null && value !== undefined) {
    elementValue = (items.indexOf(value) >= 0 ? items.indexOf(value) : undefined);
    valueText = toStringFunc(value);
  } else if(value === null && nullOptionName !== undefined) {
    elementValue = nullValue;
    valueText = nullOptionName;
  }
  if(elementValue === undefined && nullOptionName !== undefined) {
    elementValue = nullValue;
    valueText = nullOptionName;
  }

  const handleClickAway = () => {
    if(open) {
      setOpen(false);
    }
  }

  const handleToggleDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen((prevIsOpen) => !prevIsOpen);
  }

  const itemsToShow: (null | T)[] = [...items];
  if(nullOptionName !== undefined) {
    itemsToShow.unshift(null);
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={Utils.css(block(), className)}>
        <button
          className={element('dropdown', open ? 'open' : '')}
          type="button"
          aria-expanded={open}
          onClick={handleToggleDropdown}>
          {legend &&
            <span className={element('legend')}>{legend}</span>
          }
          <span className={element('label', !valueText && placeholder ? 'placeholder' : '')}>{valueText || placeholder}</span>
          {
            caret &&
            caret
          }
          {
            !caret &&
            <i className="fa fa-chevron-down" />
          }
        </button>
        <ul className={element('items', open ? 'open' : '')}>
          {itemsToShow.map((item, index) => 
            <li key={index}>
              <button
                className={element('item', value === item ? 'selected' : '')}
                tabIndex={0}
                type="button"
                onClick={() => handleChange(item)}>
                <span>{toStringFunc(item)}</span>
                <span className={element('item-checked')}></span>
              </button>
            </li>
          )}
        </ul>
      </div>
    </ClickAwayListener>
  );
};

export default Dropdown;














// import React, { MouseEvent, ReactNode, useState } from "react";
// import ClickAwayListener from "../../../framework/clickAwayListener/clickAwayListener";

// //todo: borrow look and feel of other dropdown to make consistent across browsers

// type NullDropdownValue = "<null>"
// type DropdownProps<T> = {
//   id?: string,
//   className?: string,
//   items?: T[],
//   value?: T | null,
//   onChange?: (item: T | null) => void,
//   onBlur?: () => void,
//   toStringFunc?: (item: T) => string,
//   nullOptionName?: string, //if omitted, will omit null option
//   placeholder?: string,
//   label?: string
// }
// export const Dropdown = <T extends unknown>(props: DropdownProps<T> & { children?: ReactNode }) => {
//   const [isOpen, setIsOpen] = useState<boolean>(false);
//   const nullValue: NullDropdownValue = "<null>";
//   //const toStringFunc: ((item: T) => string) = props.toStringFunc !== undefined ? props.toStringFunc : (item) => `${item}`;
//   const toStringFunc = (item: T | null) => {
//     let str: string;
//     if(item === null && props.nullOptionName) {
//       str = props.nullOptionName;
//     } else if(item !== null && props.toStringFunc !== undefined) {
//       str = props.toStringFunc(item);
//     } else {
//       str = `${item}`;
//     }
//     return str;
//   }
//   const items: T[] = props.items ? [...props.items] : [];
//   const onChange = (value: T): void => {
//     if(props.onChange !== undefined && value !== props.value) {
//       setIsOpen(false);
//       props.onChange(value);
//     }
//   }
//   let elementValue: number | undefined | NullDropdownValue = undefined;
//   let valueText: string = "";
//   if(props.value !== null && props.value !== undefined) {
//     elementValue = (items.indexOf(props.value) >= 0 ? items.indexOf(props.value) : undefined);
//     valueText = toStringFunc(props.value);
//   } else if(props.value === null && props.nullOptionName !== undefined) {
//     elementValue = nullValue;
//     valueText = props.nullOptionName;
//   }
//   if(elementValue === undefined && props.nullOptionName !== undefined) {
//     elementValue = nullValue;
//     valueText = props.nullOptionName;
//   }

//   const onClickAway = () => {
//     if(isOpen) {
//       setIsOpen(false);
//     }
//   }

//   const onToggleDropdown = (e: MouseEvent) => {
//     e.stopPropagation();
//     setIsOpen((prevIsOpen) => !prevIsOpen);
//   }

//   const selectable = true;
//   return <ClickAwayListener onClickAway={onClickAway}>
//     <div className="dropdown">
//       <button
//         className={`dropdown__dropdown ${isOpen ? 'dropdown__dropdown--open' : ''}`}
//         onClick={onToggleDropdown}>
//         {props.label &&
//           <span className="dropdown__label">{props.label}</span>
//         }
//         <span className={`${!valueText && props.placeholder ? 'dropdown__value-text--placeholder' : ''}`}>{valueText || props.placeholder}</span>
//         <span className={`dropdown__arrow ${isOpen ? 'dropdown__arrow--open' : ''}`}></span>
//       </button>
//       <ul className={`dropdown__items ${isOpen ? 'dropdown__items--open' : ''}`}>
//         {items.map((item, index) => 
//           <li key={index}>
//             <button
//               className={`dropdown__item ${props.value === item ? 'dropdown__item--selected' : ''} ${!selectable ? 'dropdown__item--not-selectable' : ''}`}
//               tabIndex={0}
//               onClick={() => selectable && onChange(item)}>
//               <span>{toStringFunc(item)}</span>
//               <span className="dropdown__item-checked"></span>
//             </button>            
//           </li>
//         )}
//       </ul>
//     </div>
//   </ClickAwayListener>
  
//   // return <select id={props.id} className={props.className} value={elementValue} onChange={onChange} onBlur={props.onBlur}>
//   //   {props.nullOptionName !== undefined &&
//   //     <option value={nullValue}>{props.nullOptionName}</option>
//   //   }
//   //   {items.map((item, index) => 
//   //     <option key={index} value={index}>{toStringFunc(item)}</option>
//   //   )}
//   // </select>  
// }


/**
 * Represents a collection of utility methods.
 */
class Utils {
  /**
   * Creates a read-only property.
   * @param {Object} object The object to create the read-only property for.
   * @param {String} name The name of the property.
   * @param {any} value The value the property will return.
   */
  public static createReadOnlyProperty(object: Object, name: string, value: any): void {
    const obj = {};
    obj[name] = {
      'get': function() { return value; }
    };
    Object.defineProperties(object, obj);
  }

  /**
   * Debounces a function.
   * @param {Function} func The function to debounce.
   * @param {Number} wait The amount in milliseconds to wait to call the function.
   * @param {Boolean} isImmediate A value that determines whether to call the function immediately.
   * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's debounce function.
   */
  public static debounce(func: Function, wait: number, isImmediate: boolean): Function {
    let timeout: number | undefined = undefined;

    return function() {
      const that = this; // eslint-disable-line @typescript-eslint/no-this-alias
      const args = arguments; // eslint-disable-line prefer-rest-params
      const callLater = () => {
        timeout = undefined;

        if (!isImmediate) {
          func.apply(that, args);
        }
      };
      const callNow = isImmediate && !timeout;

      window.clearTimeout(timeout);

      timeout = window.setTimeout(callLater, wait);

      if (callNow) {
        func.apply(that, args);
      }
    };
  }

  /**
   * Returns a cookie value.
   * @param {String} name The name of the cookie.
   * @returns String
   */
  public static getCookie(name: string): string {
    let value = '';

    if (name) {
      const cname = `${name}=`;
      const cookies = decodeURIComponent(document.cookie).split(';');

      for (let index = 0; index < cookies.length; ++index) {
        let cookie = cookies[index];

        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }

        if (cookie.indexOf(cname) === 0) {
          value = cookie.substring(cname.length, cookie.length);
          break;
        }
      }
    }

    return value;
  }

  /**
   * Returns a url/query string parameter.
   * @param {String} name The name of the url/query string parameter.
   * @returns String
   */
  public static getUrlParam(name: string): string {
    let value: string | null = '';

    if (typeof URLSearchParams !== 'undefined') {
      value = new URLSearchParams(window.location.search).get(name);
    }
    else {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      const params = new RegExp(`[\\?&]${name}=([^&#]*)`).exec(location.search);
      value = params === null ? '' : decodeURIComponent(params[1].replace(/\+/g, ' '));
    }

    value = value === null ? '' : value;

    return value;
  }

  /**
   * Calls a function once.
   * @param {Function} func The function to call.
   * @param {Object} context The context in which to execute the function.
   * @returns any
   * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's once function.
   */
  public static once(func: Function, context: Object): any {
    let result = null;

    return function() { 
      if (func) {
        result = func.apply(context || this, arguments); // eslint-disable-line prefer-rest-params
        func = null as any;
      }

      return result;
    };
  }

  /**
   * Polls a function.
   * @param {Function} func The function to poll.
   * @param {Number} timeout The amount in milliseconds to wait to call the function.
   * @param {Number} interval The amount in milliseconds to wait to poll.
   * @returns Promise
   * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's poll function.
   */
  public static poll<T>(func: () => T, timeout: number, interval: number): Promise<T> {
    const endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    const checkCondition = (resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => {
      const result = func();

      // If the condition is met, we're done! 
      if (result) {
        resolve(result);
      }
      // If the condition isn't met, but the timeout hasn't elapsed, go again.
      else if (Number(new Date()) < endTime) {
        setTimeout(checkCondition, interval, resolve, reject);
      }
      // Didn't match and too much time, reject!
      else {
        reject(new Error(`timed out for ${func}: ${arguments}`)); // eslint-disable-line prefer-rest-params
      }
    };

    return new Promise<T>(checkCondition);
  }

  /**
   * Sets a cookie value.
   * @param {String} name The name of the cookie.
   * @param {any} value The value of the cookie.
   * @param {Number} expireDays The number of days until the cookie expires.
   */
  public static setCookie(name: string, value: any, expireDays: number): void {
    let expires = '';
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    expires = `expires=${expireDate.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  /**
   * Sets the document body and html element overflow to hidden to prevent scrolling.
   */
  public static setDocumentOverflowToHidden(): void {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  }

  /**
   * Sets the document body and html element overflow back to its initial value to allow scrolling.
   */
  public static setDocumentOverflowToInitial(): void {
    document.body.style.overflow = 'visible';
    document.documentElement.style.overflow = 'visible';
  }

  /**
   * Removes an item from an array. By default, creates a new array and returns it without modifying the supplied array.
   * @param {Array} arr The array.
   * @param {Object} item The item to remove.
   * @param {Boolean} inPlace Optional. If true, removes the item from the supplied array. If false (default), creates a new array.
   * @param {Function} eq Optional. Equality comparator. If omitted, uses a default comparison.
   * @returns Array
   */
  public static arrayRemove = <T>(arr: T[], item: T, inPlace?: boolean, eq?: (item1: T, item2: T) => boolean): T[] => {
    const newArr: T[] = inPlace === true ? arr : [...arr];
    const index: number = eq === undefined ? newArr.indexOf(item) : newArr.findIndex(ele => eq(ele, item));
    if(index > -1) {
      newArr.splice(index, 1);
    }
    return newArr;
  }

  /**
   * Takes an array and returns a new array with all duplicates removed.
   * @param {Array} arr The array.
   * @param {Function} eq Optional. Equality comparator. If omitted, uses "===" as a default comparison.
   * @returns Array
   */
  public static arrayReturnUnique = <T>(arr: T[], eq?: (item1: T, item2: T) => boolean): T[] => {
    const eqFunc: ((item1: T, item2: T) => boolean) = eq !== undefined ? eq : (item1, item2) => item1 === item2;
    return arr.filter((value, index, array) => array.findIndex(item => eqFunc(item, value)) === index);
  }

  /**
   * Compares two arrays. Returns true if they are of the same length and have identical elements at the same indices.
   * @param {Array} arr1 The first array.
   * @param {Array} arr2 The second array.
   * @param {Function} eq Optional. Equality comparator. If omitted, uses "===" as a default comparison.
   * @returns Boolean
   */
  public static arrayCompare = <T>(arr1: T[], arr2: T[], eq?: (item1: T, item2: T) => boolean): boolean => {
    const eqFunc: ((item1: T, item2: T) => boolean) = eq !== undefined ? eq : (item1, item2) => item1 === item2;
    let equal: boolean = true;
    if(arr1.length !== arr2.length) {
      equal = false;
    } else {
      for(let i = 0; i < arr1.length; i++) {
        if(!eqFunc(arr1[i], arr2[i])) {
          equal = false;
          break;
        }
      }
    }
    return equal;
  }

  /**
   * Compares two arrays. Returns true if they are of the same length and have the same set of elements, regardless of sort order.
   * @param {Array} arr1 The first array.
   * @param {Array} arr2 The second array.
   * @param {Function} eq Optional. Equality comparator. If omitted, uses "===" as a default comparison.
   * @returns Boolean
   */
  public static setCompare = <T>(arr1: T[], arr2: T[], eq?: (item1: T, item2: T) => boolean): boolean => {
    const eqFunc: ((item1: T, item2: T) => boolean) = eq !== undefined ? eq : (item1, item2) => item1 === item2;
    return arr1.length === arr2.length && arr1.every(val1 => arr2.some(val2 => eqFunc(val2, val1)));
  }
  
  /**
   * Returns the supplied string, or a truncated string with an ellipsis added if the string is too long.
   * @param {String} str The string whose length to limit.
   * @param {Number} maxChars The maximum number of characters the string can have before truncation.
   * @returns String
   */
  public static limitString = (str: string, maxChars: number): string => {
    let limited: string = str;
    if(str.length > maxChars) {
      if(maxChars > 3) {
        limited = `${str.substring(0, maxChars - 3)}...`;
      } else {
        limited = str.substring(0, maxChars);
      }
    }
    return limited;
  }

  /**
   * Pads a number with enough zeroes to meet the specified number of digits, and returns it as a string.
   * @param {Number} num The number to pad
   * @param {Number} digits Optional. The number of digits to pad to. Defaults to 2.
   * @returns String
   */
  public static zeroPad = (num: number, digits: number = 2): string => {
    let str = `${num}`;
    while(str.length < digits) {
      str = `0${str}`;
    }
    return str;
  }

  /**
   * Produces a single HTML class string from a group of strings. For convenience, allows "undefined" values to
   * be passed in, which are ignored in the final string. If the final string is empty, returns undefined which
   * will prevent a "class" attribute from appearing on the HTML tag when rendered.
   * @param {Array} classes The classes to be sent in
   * @returns The final CSS string
   */
  public static css = (...classes: (string | undefined)[]): string | undefined => {
    let str: string | undefined = "";
    for (const cls of classes) {
      if (cls !== undefined) {
        if (str !== "") {
          str += " ";
        }
        str += cls;
      }
    }
    return str === "" ? undefined : str;
  }

  /**
   * Opens a save dialog to save the provided blob as a file.
   * @param blob The file contents
   * @param filename The default filename
   */
  public static downloadFile = (blob: Blob, filename: string): void => {
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
  
  // Lock object to prevent modification (true static).
  Object.freeze(Utils);
  
  export default Utils;
  
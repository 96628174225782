import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import {
  setDocumentOverflowToHidden,
  setDocumentOverflowToInitial
} from '../../utils/domUtils';
import bemify from '../../utils/bemUtils';

/**
 * Represents an overlay that can contain any content.
 * @param {Object} props The component properties.
 */
const Overlay = (props) => {
  const {
    children,
    show
  } = props;
  const overlayRef = useRef();

  if (show) {
    setDocumentOverflowToHidden();
  }
  else if (!show && overlayRef.current?.classList.contains('overlay--visible')) {
    setDocumentOverflowToInitial();
  }

  const [block] = bemify('overlay');

  return (
    <>
      {
        createPortal(
          <div
            className={block(show ? 'visible' : '')}
            ref={overlayRef}>
            {children}
          </div>,
          document.body
        )
      }
    </>
  );
};

Overlay.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool
};

export default Overlay;

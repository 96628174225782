import jsBemify from '../utils/bemUtils';

/**
 * Returns functions to assist with BEM class names in components.
 * @param {String} block The block level component name.
 * @returns Array
 */
const bemify = (block: string): [(modifiers?: string) => string, (element: string, modifiers?: string | string[]) => string] => {
  return jsBemify(block);
}

export default bemify;

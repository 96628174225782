import { Types } from '../enums';
import { isType } from '../utils/typeUtils';

if (!Array.prototype.equals) {
  Array.prototype.equals = function(arr) {
    if (!isType(arr, Types.array)) {
      return false;
    }

    if (this.length !== arr.length) {
      return false;
    }

    return JSON.stringify(this) === JSON.stringify(arr);
  };
}

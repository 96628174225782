import { Types } from '../enums';
import { isType } from './typeUtils';

/**
 * Generates the class names from the base class and modifiers.
 * @param {String} className The base class name (block or block+element).
 * @param {String|String[]} modifiers The string or array of strings representing the modifiers to apply.
 * @returns Array
 */
function generateClassName(className, modifiers) {
  if (!modifiers) {
    return className;
  }

  let classOut = className;

  if (isType(modifiers, Types.string)) {
    classOut += ` ${className}--${modifiers}`;
  }
  else if (Array.isArray(modifiers)) {
    for (const modifier of modifiers) {
      if (modifier) {
        classOut += ` ${className}--${modifier}`;
      }
    }
  }

  return classOut;
}

/**
 * Returns functions to assist with BEM class names in components.
 * @param {String} block The block level component name.
 * @returns Array
 */
function bemify(block) {
  return [
    modifiers => generateClassName(block, modifiers),
    (element, modifiers) => generateClassName(`${block}__${element}`, modifiers)
  ];
}

export default bemify;

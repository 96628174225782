import React, { useEffect } from 'react';
import { createContext, useState } from "react";
import { EnvSettings, SettingsService } from '../../services/settingsService';
import { useOverlay } from '../../hooks/useOverlay';

//Context for providing app settings to descendants
export const SettingsContext = createContext<EnvSettings>({} as EnvSettings);

type WithSettingsProps = {
  children: React.ReactNode
}

/**
 * This component fetches environment settings and places them in a context, and does not load children
 * until settings are established.
 */
export const WithSettings = (props: WithSettingsProps) => {
  const [settings, setSettings] = useState<EnvSettings | undefined>(undefined);
  const [attempted, setAttempted] = useState<boolean>(false);
  const overlay = useOverlay();

  useEffect(() => {
    const fetchSettings = async () => {
      overlay.show();
      const fetchedSettings = await SettingsService.getSettings();
      overlay.hide();
      if(fetchedSettings !== undefined) {
        setSettings(fetchedSettings);
      }
      setAttempted(true);
    };
    fetchSettings();
  }, [overlay]);

  const noSettingsMsg = attempted ? "Failed to fetch app settings" : "";
  return settings === undefined ? <>{noSettingsMsg}</> : <SettingsContext.Provider value={settings}>
    {props.children}
  </SettingsContext.Provider>
}

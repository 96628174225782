import { EnvSettings } from "../../services/settingsService";

type MsalAuthConfig = {
    clientId: string,
    authority: string,
    knownAuthorities: string[],
    redirectUri: string,
    postLogoutRedirectUri: string,
    scopes: string[]
}
type MsalConfig = {
    auth: MsalAuthConfig
}

type PolicyKey = 'signInLocal' | 'signInSvs' | 'signInJA'
type B2CPolicies = {
    names: {
        [key in PolicyKey]: string
    },
    authorities: {
        [key in PolicyKey]: MsalAuthConfig
    }
}

//https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
export const getMsalConfig = (settings: EnvSettings): MsalConfig => ({
    auth: {
        clientId: settings.REACT_APP_B2CAAD_CLIENT_ID ||"",
        authority: `https://${settings.REACT_APP_B2CAAD_AUTHORITY}/${settings.REACT_APP_B2CAAD_TENANT}/${settings.REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW}`||"",
        knownAuthorities: [settings.REACT_APP_B2CAAD_AUTHORITY||""],
        redirectUri: settings.REACT_APP_B2CAAD_REDIRECT_URI,
        //postLogoutRedirectUri: "/logout",
        postLogoutRedirectUri: settings.REACT_APP_B2CAAD_REDIRECT_URI,
        scopes: [`https://${settings.REACT_APP_B2CAAD_TENANT}/4pcCssSpa/user_login`]
    }
});

export const getMsalConfigJA = (settings: EnvSettings): MsalConfig => ({
    auth: {
        clientId: `${settings.REACT_APP_B2CAAD_CLIENT_ID}`||"",
        authority: `https://${settings.REACT_APP_B2CAAD_AUTHORITY}/${settings.REACT_APP_B2CAAD_TENANT}/${settings.REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW}`||"",
        knownAuthorities: [`${settings.REACT_APP_B2CAAD_AUTHORITY}`||""],
        redirectUri: settings.REACT_APP_B2CAAD_REDIRECT_URI,
        //postLogoutRedirectUri: "/logout",
        postLogoutRedirectUri: `${settings.REACT_APP_B2CAAD_REDIRECT_URI}?authExperience=ja`,
        scopes: ['openid',  settings.REACT_APP_B2CAAD_CLIENT_ID||""]
    }
});

export const getMsalConfigSvsVision = (settings: EnvSettings): MsalConfig => ({
    auth: {
        clientId: `${settings.REACT_APP_B2CAAD_CLIENT_ID}`||"",
        authority: `https://${settings.REACT_APP_B2CAAD_AUTHORITY}/${settings.REACT_APP_B2CAAD_TENANT}/${settings.REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW}`||"",
        knownAuthorities: [`${settings.REACT_APP_B2CAAD_AUTHORITY}`||""],
        redirectUri: settings.REACT_APP_B2CAAD_REDIRECT_URI,
        //postLogoutRedirectUri: "/logout",
        postLogoutRedirectUri: `${settings.REACT_APP_B2CAAD_REDIRECT_URI}/?authExperience=svsvision`,
        scopes: ['openid',  settings.REACT_APP_B2CAAD_CLIENT_ID||""]
    }
});

export const getB2cPolicies = (settings: EnvSettings): B2CPolicies => ({
    names: {
        signInLocal: "SignInLocal",
        signInSvs: "SignInSvs",
        signInJA: "SignInJA",
    },
    authorities: {
        signInSvs: {
            clientId: settings.REACT_APP_B2CAAD_CLIENT_ID,
            authority: `https://${settings.REACT_APP_B2CAAD_AUTHORITY}/${settings.REACT_APP_B2CAAD_TENANT}/${settings.REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW}`,
            knownAuthorities: [settings.REACT_APP_B2CAAD_AUTHORITY],
            redirectUri: settings.REACT_APP_B2CAAD_REDIRECT_URI,
            postLogoutRedirectUri: `${settings.REACT_APP_B2CAAD_REDIRECT_URI}logout`,
            scopes: ['openid',  settings.REACT_APP_B2CAAD_CLIENT_ID]
        },
        signInLocal: {
            clientId: settings.REACT_APP_B2CAAD_CLIENT_ID,
            authority: `https://${settings.REACT_APP_B2CAAD_AUTHORITY}/${settings.REACT_APP_B2CAAD_TENANT}/${settings.REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW}`,
            knownAuthorities: [settings.REACT_APP_B2CAAD_AUTHORITY],
            redirectUri: settings.REACT_APP_B2CAAD_REDIRECT_URI,
            postLogoutRedirectUri: `${settings.REACT_APP_B2CAAD_REDIRECT_URI}logout`,
            scopes: ['openid',  settings.REACT_APP_B2CAAD_CLIENT_ID]
        },
        signInJA: {
            clientId: settings.REACT_APP_B2CAAD_CLIENT_ID,
            authority: `https://${settings.REACT_APP_B2CAAD_AUTHORITY}/${settings.REACT_APP_B2CAAD_TENANT}/${settings.REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW}`,
            knownAuthorities: [settings.REACT_APP_B2CAAD_AUTHORITY],
            redirectUri: settings.REACT_APP_B2CAAD_REDIRECT_URI,
            postLogoutRedirectUri: `${settings.REACT_APP_B2CAAD_REDIRECT_URI}logout`,
            scopes: ['openid',  settings.REACT_APP_B2CAAD_CLIENT_ID]
        }
    }
});

export function getB2cConfigExperience(settings: EnvSettings, authExperience="default"): MsalConfig {
    if (authExperience==="svsvision")
    {
        return getMsalConfigSvsVision(settings);
    }
    else if (authExperience==="ja")
    {
        return getMsalConfigJA(settings);
    }
    else {
        return getMsalConfig(settings);
    }
}


//Env file variables needed
// ##Client Side AD Variables
// REACT_APP_B2CAAD_AUTHORITY=4patientcaredev.b2clogin.com
// REACT_APP_B2CAAD_TENANT=4patientcaredev.onmicrosoft.com
// ##### Client with only Authorization Code Flow 
// REACT_APP_B2CAAD_CLIENT_ID=77da0c90-4675-42e2-8bb9-c2b9db251aee
// REACT_APP_B2CAAD_TENAT_ID=28fc56fa-c293-499e-b612-292f1818d4db
// REACT_APP_B2CAAD_LOCAL_SIGNIN_USERFLOW = B2C_1_SignInLocal
// REACT_APP_B2CAAD_LOCAL_SIGNIN2_USERFLOW = B2C_1_SignIn2
// REACT_APP_B2CAAD_SVSVISION_SIGNIN_USERFLOW = B2C_1_SignInLocalAndOktaSvs
// REACT_APP_B2CAAD_JA_SIGNIN_USERFLOW = B2C_1_OktaJASignin
// REACT_APP_B2CAAD_REDIRECT_URI=https://localhost:3000/


import React from 'react';
import Router from './router';
import ErrorBoundary from './errorBoundary';
import { Withs } from './components/withs/Withs';

/**
 * Represents the entry point into the application.
 */
const App = () => {
  return (
    <ErrorBoundary>
      <Withs>
        <Router />
      </Withs>
    </ErrorBoundary>
  );
};

export default App;

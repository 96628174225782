import { useContext, useState } from "react"
import { EnvSettings } from "../services/settingsService";
import { SettingsContext } from "../components/withSettings/WithSettings";

/**
 * Hook for accessing the environment settings
 * @returns The environment settings
 */
export const useSettings = (): EnvSettings => {
  const contextSettings = useContext(SettingsContext);

  //probably overkill (since context value shouldn't change) but this ensures immutability so it can be used in useEffect deps arrays
  const [settings] = useState<EnvSettings>(contextSettings);
  return settings;
}


import React, { useCallback } from 'react';
import { createContext, useState } from "react";
import SpinnerLoader from '../../framework/loader/spinnerLoader';

//Context for providing descendants access to the overlay control
export const OverlayContext = createContext<(show: boolean) => void>(() => {});

type WithOverlayProps = {
  children: React.ReactNode
}

/**
 * This component adds a spinner loader to the application and provide a context so any descendant component can
 * access the spinner without needing to add its own spinner. It also manages the spinner by ensuring that the spinner
 * is not switched off until every switch-on has been undone (this way a quicker async method cannot turn off the
 * spinner activated by a longer async method that is still running). Currently this is done with a simple counter.
 */
export const WithOverlay = (props: WithOverlayProps) => {
  //Current overlay count (i.e. number of times a descendant component has turned on the overlay and has not yet turned it off)
  const [overlayCount, setOverlayCount] = useState<number>(0);

  //The method sent to the descendants via context, to allow them to switch on and off the overlay
  const setOverlayShown = useCallback((show: boolean) => {
    if(show) {
      setOverlayCount(prev => prev + 1);
    } else {
      setOverlayCount(prev => prev === 0 ? 0 : prev - 1);
    }
  }, []);

  //Whether to show the overlay in the DOM
  const showLoadingOverlay = (overlayCount > 0);

  return <OverlayContext.Provider value={setOverlayShown}>
    <SpinnerLoader show={showLoadingOverlay} />
    {props.children}
  </OverlayContext.Provider>
}
